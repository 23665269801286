import classNames from 'classnames';
import classnames from 'classnames';
import DataGrid from 'components/DataGrid';
import { SelectApiColumnFilter } from 'components/DataGrid/Filter';
import SecuredView from 'components/Theme/Common/SecuredView';
import ProductQuantity from 'pages/Manufacture/Product/ListComponents/ProductQuantity';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ButtonGroup } from 'reactstrap';

const ChildGrid = props => {
  const columns = useMemo(
    () => [
      {
        id: 'labelerId',
        filterable: true,
        sortable: true,
        Header: 'ID',
        Cell: ({ row }) => {
          return (
            <div>
              <div>
                {(row.original.requiredProductsCount ?? row.original.requiredProductsCount ?? 0) > 0 && (
                  <i
                    style={{ color: `${row.original?.type?.color || '#484f56'}` }}
                    className={classNames('mdi font-size-16', {
                      [`mdi-${row.original.type?.icon ?? 'folder'}`]: true,
                    })}
                  />
                )}
                {(row.original.requiredProductsCount ?? row.original.requiredProductsCount ?? 0) === 0 && (
                  <i
                    style={{ color: `${row.original?.type?.color || '#484f56'}` }}
                    className={`mdi mdi-${row.original.type?.icon ?? 'text-box-outline'} font-size-16`}
                  />
                )}
                <span className="d-inline-block" style={{ marginLeft: 4 }}>
                  {row.original.labelerId}
                </span>
              </div>
            </div>
          );
        },
        accessor: () => {
          return null;
        },
        width: 100,
      },
      {
        id: 'name',
        Header: 'Nazwa',
        accessor: 'name',
      },
      {
        id: 'symbol',
        Header: 'Symbol',
        accessor: 'symbol',
      },
      {
        id: 'ean',
        Header: 'EAN',
        accessor: 'ean',
      },
      {
        id: 'product.productQuantity.quantity',
        Header: 'Dost.',
        accessor: ({ unit, productQuantity, warehouseQuantities }) => (
          <ProductQuantity
            quantity={productQuantity?.quantity ?? 0.0}
            unit={unit}
            details={warehouseQuantities.map(el => ({ name: el.warehouse?.name ?? 'Brak przypisanego magazynu', quantity: el.quantity }))}
          />
        ),
      },
      {
        id: 'quantityReserved',
        Header: 'Rez.',
        accessor: ({ quantityReserved }) => quantityReserved ?? 0.0,
      },
      {
        id: 'group.id',
        Header: 'Grupa',
        accessor: 'product.group.name',
        Filter: SelectApiColumnFilter,
        filterSearchUrl: '/manufacture/product-groups',
        filterSearchField: 'id',
        minWidth: 160,
      },
      {
        id: 'type.id',
        Header: 'Typ',
        accessor: 'product.type.name',
        Filter: SelectApiColumnFilter,
        filterSearchUrl: '/manufacture/product-types',
        filterSearchField: 'id',
        minWidth: 160,
      },
      {
        id: 'active',
        Header: 'Aktywny',
        accessor: ({ product }) => {
          return (
            <div className="font-size-20 text-center" style={{ margin: '0 auto' }}>
              <i
                className={classnames('mdi', {
                  'mdi-check-circle text-success': product?.active === true,
                  'mdi-minus-circle text-danger': product?.active !== true,
                })}
              />
            </div>
          );
        },
      },
      {
        id: 'actionsStickyRight',
        Header: 'Akcje',
        disableSortBy: true,
        disableFilters: true,
        maxWidth: '140px',
        accessor: row => (
          <ButtonGroup>
            <SecuredView role="ROLE_EDIT_MANUFACTURE_PRODUCT">
              <Link to={`/manufacture/products/${row.id}/edit`} className="btn btn-primary  btn-sm">
                <i className="mdi mdi-cogs" />
              </Link>
            </SecuredView>
            <Link to={`/manufacture/products/${row.id}`} className="btn btn-success  btn-sm">
              <i className="mdi mdi-eye" />
            </Link>
          </ButtonGroup>
        ),
        ...(props.modalListActions?.[0] ?? {}),
      },
    ],
    [],
  );

  const renderRowSubComponent = React.useCallback(
    ({ row }) => (
      <ChildGrid url={`${row.original['@id']}/required-products?productsOnly=true`} modalListActions={props.modalListActions} />
      // <ProductTechnologies {...props} row={row}/>
    ),
    [],
  );

  return (
    <div style={{ padding: '0 0 0 30px', width: '100%' }}>
      <div className="list-tree w-100">
        <DataGrid
          defaultPerPage={50}
          disableTop={true}
          disableHeader={true}
          disableFooter={true}
          fullWidth={true}
          defaultOrderBy={[{ id: 'labelerId', desc: true }]}
          expandAll={true}
          responsive={false}
          allowChangeLimit={false}
          minRows={0}
          className={'table-clickable'}
          columns={columns}
          url={props.url ?? '/manufacture/products'}
          history={props.history}
          renderRowSubComponent={renderRowSubComponent}
          canRenderSubRow={({ column: { id }, row: { original } }) => {
            if (id === 'actions') {
              return false;
            }

            return (original.requiredProductsCount ?? original.requiredProductsCount ?? 0) > 0;
          }}
        />
      </div>
    </div>
  );
};

export default ChildGrid;
