import { FieldType } from '@Components/CustomFields/FieldTypes';
import BooleanTypeConfiguration from 'pages/Configuration/CustomFields/TypeConfiguration/BooleanTypeConfiguration';
import DictionaryTypeConfiguration from 'pages/Configuration/CustomFields/TypeConfiguration/DictionaryTypeConfiguration';
import DividerTypeConfiguration from 'pages/Configuration/CustomFields/TypeConfiguration/DividerTypeConfiguration';
import FileTypeConfiguration from 'pages/Configuration/CustomFields/TypeConfiguration/FileTypeConfiguration';
import MathTypeConfiguration from 'pages/Configuration/CustomFields/TypeConfiguration/MathTypeConfiguration';
import NumberTypeConfiguration from 'pages/Configuration/CustomFields/TypeConfiguration/NumberTypeConfiguration';
import RelationTypeConfiguration from 'pages/Configuration/CustomFields/TypeConfiguration/RelationTypeConfiguration';
import TextareaTypeConfiguration from 'pages/Configuration/CustomFields/TypeConfiguration/TextareaTypeConfiguration';
import YouTubeTypeConfiguration from 'pages/Configuration/CustomFields/TypeConfiguration/YouTubeTypeConfiguration';

export const DEFAULT_CONFIG: { [key in FieldType]?: any } = {
  RELATION: { targetModule: null },
  YOU_TUBE: { height: 450 },
  BOOLEAN: { component: 'SWITCH' },
  FILE: { multiple: false },
  DICTIONARY: { dictionaryId: null },
  MATH: { math: null },
  NUMBER: { predefinedOptions: '' },
  DIVIDER: { label: '' },
  TEXT: { editor: null },
};
const configurations: { [key in FieldType]?: any } = {
  RELATION: RelationTypeConfiguration,
  YOU_TUBE: YouTubeTypeConfiguration,
  BOOLEAN: BooleanTypeConfiguration,
  FILE: FileTypeConfiguration,
  DICTIONARY: DictionaryTypeConfiguration,
  MATH: MathTypeConfiguration,
  NUMBER: NumberTypeConfiguration,
  DIVIDER: DividerTypeConfiguration,
  TEXT: TextareaTypeConfiguration,
};

export default configurations;
