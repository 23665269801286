import ProductListScannerWrapper from './ProductListScanner';
import { PhoneAndroid } from '@mui/icons-material';
import { Alert, Button, Paper, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { ContractorModule, EmployeeModule, ProductionDemandModule, ProductionOrderModule, WarehouseModule } from 'Modules/CTMModules';
import PlaceEventTab from 'Modules/Warehouse/Components/PlaceEvent/PlaceEventTab';
import PrintItemButton from 'components/DataGrid/Button/PrintItemButton';
import CurrencySelectSingle from 'components/Form/CurrencySelectSingle';
import CheckboxInput from 'components/Form/MUI/CheckboxInput';
import DateTimePickerInput from 'components/Form/MUI/DateTimePickerInput';
import TextInput from 'components/Form/MUI/TextInput';
import HistoryModuleButton from 'components/History/HistoryModuleButton';
import ModuleListPicker from 'components/Module/ModuleListPicker';
import RecordInputReader from 'components/Module/RecordInputReader';
import BaseForm from 'components/Theme/Common/BaseForm';
import Loader from 'components/Theme/Common/Loader';
import { Col, Container } from 'components/Theme/Grid';
import { useModuleContext } from 'context/ModulesContext';
import dayjs from 'dayjs';
import * as DocumentApi from 'helpers/Api/Warehouse/Document/Document';
import { get } from 'helpers/Axios';
import { useErrorHandler } from 'helpers/FormHandler';
import FormProductRow, { DefaultProductRow } from 'pages/Warehouse/Document/FormComponents/FormProductRow';
import StatusPicker from 'pages/Warehouse/Document/FormComponents/StatusPicker';
import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import { useAppSelector } from 'store';
import { clearErrors } from 'store/form/errors/actions';
import { v4 as uuidv4 } from 'uuid';

const Form = ({ match, readonly = undefined }) => {
  const [loading, setLoading] = useState(true);
  const searchParams = new URLSearchParams(useLocation().search);
  const [form, setForm] = useState({
    recipient: null,
    supplier: null,
    issuer: null,
    type: null,
    number: null,
    currency: null,
    warehouse: null,
    issueDate: dayjs().format('YYYY-MM-DDTHH:mm:ssZ'),
    rows: [],
    place: 'Tomaszów Mazowiecki',
    stocktaking: false,
    skipStockValidation: false,
  });
  const [ownerModuleName, setOwnerModuleName] = useState(null);

  const DocumentTypesModule = useModuleContext('warehouse-document-types');
  const DocumentModule = useModuleContext('warehouse-document');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const errorHandler = useErrorHandler();

  const typeId = match?.params?.type || null;
  const id = match?.params?.id || null;
  const owner = searchParams.get('owner') || null;

  const isGrantedSkipStockValidation = false;

  const { defaultCurrency, userDetails } = useAppSelector(
    state => ({
      defaultCurrency: state.AppConfiguration.configuration.Multinational.defaultCurrency,
      userDetails: state.Login.user.details,
    }),
    shallowEqual,
  );

  const updateFormValue = (value, field) => {
    setForm(prevForm => ({ ...prevForm, [field]: value }));
  };

  const prepareDataToSave = data => {
    const totalPriceNet = data.rows.map(el => (el.quantity ?? 1) * el.unitPriceNet).reduce((p, c) => c + p, 0);
    const totalPriceGross = data.rows.map(el => (el.quantity ?? 1) * el.unitPriceGross).reduce((p, c) => c + p, 0);
    return {
      ...data,
      recipient: data.recipient?.['@id'] ?? data.recipient ?? null,
      supplier: data.supplier?.['@id'] ?? data.supplier ?? null,
      number: data.number?.['@id'] ?? data.number ?? null,
      type: data.type?.['@id'] ?? data.type ?? null,
      issuer: data.issuer?.['@id'] ?? data.issuer ?? null,
      warehouse: data.warehouse?.['@id'] ?? data.warehouse ?? null,
      rows: data.rows.map(el => ({
        ...el,
        product: el.product?.['@id'] ?? el.product ?? null,
        place: el.place?.['@id'] ?? el.place ?? null,
        unit: el.unit?.['@id'] ?? el.unit ?? null,
      })),
      totalPriceNet: totalPriceNet,
      totalPriceGross: totalPriceGross,
      totalPriceVat: totalPriceGross - totalPriceNet,
    };
  };

  const handleAccept = () => {
    setIsSubmitting(true);
    dispatch(clearErrors());

    const data = { ...prepareDataToSave(form), status: 2000 };

    DocumentApi.update(id, data)
      .catch(errorHandler)
      .then(() => history.push('/warehouse/documents'));
  };

  const handleSubmit = (redirect = false) => {
    setIsSubmitting(true);
    dispatch(clearErrors());
    const data = prepareDataToSave(form);
    if (id) {
      DocumentApi.update(id, data)
        .catch(errorHandler)
        .then(() => (redirect ? history.push('/warehouse/documents') : null))
        .finally(() => setIsSubmitting(false));
    } else {
      DocumentApi.create(data)
        .then(result => (redirect ? history.push('/warehouse/documents') : history.push(`/warehouse/documents/${result.id}/edit`)))
        .catch(errorHandler)
        .finally(() => setIsSubmitting(false));
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    dispatch(clearErrors());
    const fetchData = async () => {
      if (id) {
        const document = await DocumentApi.fetch(id);
        setForm({
          ...form,
          ...document,
        });

        return;
      } else if (searchParams.get('copy')) {
        const document = await DocumentApi.fetch(searchParams.get('copy'));
        delete document['@id'];
        delete document['@type'];
        delete document['id'];
        delete document['number'];
        setForm({
          ...form,
          ...document,
          status: 1000,
          rows: document.rows.map(el => {
            delete el['@id'];
            delete el['@type'];
            delete el['id'];
            return { ...el, placesInfo: [] };
          }),
        });

        return;
      }

      const documentType = await DocumentTypesModule.api.get({ id: typeId });
      const warehouse = await WarehouseModule.api.get({ id: searchParams.get('warehouse') });

      setForm({
        ...form,
        currency: defaultCurrency,
        type: documentType,
        recipient: documentType?.defaultRecipient,
        supplier: documentType?.defaultSupplier,
        issuer: userDetails,
        warehouse: warehouse,
        owner: owner,
      });

      setTimeout(() => {
        if (!owner) {
          return;
        }

        get(owner).then(record => {
          setForm(prevForm => ({
            ...prevForm,
            rows: [
              ...record.rows.map(row => ({
                _key: uuidv4(),
                ...DefaultProductRow,
                product: row.product,
                unit: row.product?.unit ?? null,
                quantity: 0,
              })),
            ],
          }));
        });
      }, 1000);
    };

    fetchData().then(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (!form.type) {
      return;
    }

    if (form.type.placeEvent === 'STORE_PRODUCED') {
      setOwnerModuleName(ProductionOrderModule);
      return;
    }

    if (form.type.placeEvent === 'STORE' && !form.type.internal) {
      setOwnerModuleName(ProductionDemandModule);
    }
  }, [form.type]);

  const onNewItem = newItem => {
    newItem = { ...newItem, place: form.rows[0]?.place };
    setForm(prevForm => ({
      ...prevForm,
      rows: [...prevForm.rows, { _key: uuidv4(), ...newItem }],
    }));
  };
  const onAcceptedScannerInput = scannerInput => {
    console.log(scannerInput);
    setForm(prevForm => ({
      ...prevForm,
      rows: [
        ...prevForm.rows,
        ...scannerInput.map(el => ({
          _key: uuidv4(),
          product: el.product,
          unit: el.unit,
          quantity: el.quantity,
          vat: 23,
          unitPriceNet: parseInt(el.product.lastSupplyPrice.toFixed(2) * 100),
          unitPriceGross: parseInt(parseInt(el.product.lastSupplyPrice.toFixed(2) * 100) * (1 + 23 / 100)),
          place: null,
        })),
      ],
    }));
  };

  const onUpdateItem = (index, newItem) => {
    setForm(prevForm => {
      const newItems = [...prevForm.rows];
      newItems[index] = newItem;

      return {
        ...prevForm,
        rows: newItems,
      };
    });
  };

  const onRemove = index => {
    setForm(prevForm => ({
      ...prevForm,
      rows: prevForm.rows.filter((r, i) => i !== index),
    }));
  };

  const DetailsTab = () => (
    <>
      <Container justifyContent="space-between">
        <Col xs={12} md={4} lg={3}>
          <Container spacing={2}>
            <Col xs={12}>
              <ModuleListPicker
                moduleName={'contractor-contractor'}
                onChange={row => updateFormValue(row, 'supplier')}
                disableClear={!form.supplier}
              >
                <RecordInputReader
                  readonly={readonly}
                  value={form?.supplier}
                  name="supplier"
                  module={ContractorModule}
                  label={'Dostawca'}
                />
              </ModuleListPicker>
            </Col>
            <Col xs={12}>
              <ModuleListPicker
                moduleName={'contractor-contractor'}
                onChange={row => updateFormValue(row, 'recipient')}
                disableClear={!form.recipient}
              >
                <RecordInputReader
                  readonly={readonly}
                  value={form?.recipient}
                  name="recipient"
                  module={ContractorModule}
                  label={'Odbiorca'}
                />
              </ModuleListPicker>
            </Col>
            <Col xs={12}>
              <ModuleListPicker
                moduleName={'employee-employee'}
                onChange={row => updateFormValue(row, 'issuer')}
                disableClear={!form.issuer}
              >
                <RecordInputReader readonly={readonly} value={form?.issuer} name="issuer" module={EmployeeModule} label={'Wystawca'} />
              </ModuleListPicker>
            </Col>
          </Container>
        </Col>

        <Col xs={12} md={4} lg={3}>
          <Container spacing={2}>
            <Col xs={12}>
              <DateTimePickerInput
                label={'Data wystawienia *'}
                value={form.issueDate}
                name="issueDate"
                onChange={updateFormValue}
                disabled={readonly}
              />
            </Col>
            <Col xs={12}>
              <TextInput label={'Miejsce wystawienia *'} value={form.place} name={'place'} onChange={updateFormValue} disabled={readonly} />
            </Col>
            <Col xs={12}>
              <ModuleListPicker
                moduleName={'warehouse-warehouse'}
                onChange={row => updateFormValue(row, 'warehouse')}
                disableClear={!form.warehouse}
              >
                <RecordInputReader
                  readonly={readonly}
                  name="warehouse"
                  value={form?.warehouse}
                  module={WarehouseModule}
                  label={'Magazyn'}
                  inputProps={{ style: { color: 'red' } }}
                />
              </ModuleListPicker>
            </Col>
          </Container>
        </Col>

        <Col xs={12} md={4} lg={3}>
          <Container>
            <Col xs={12}>
              <TextInput label={'Numer *'} disabled={true} value={form.number} name={'number'} />
            </Col>
            <Col xs={12}>
              <CurrencySelectSingle value={form.currency} name="currency" onChange={updateFormValue} />
            </Col>
            {form.type.internal && form.type.placeEvent === 'STORE' && (
              <Col xs={12}>
                <CheckboxInput
                  value={form.stocktaking}
                  name={'stocktaking'}
                  onChange={updateFormValue}
                  label={'Inwentaryzacja miejsca magazynowego'}
                />
              </Col>
            )}
            {isGrantedSkipStockValidation && form.release && (
              <Col xs={12}>
                <CheckboxInput
                  value={form.skipStockValidation}
                  name={'skipStockValidation'}
                  onChange={updateFormValue}
                  label={'Pomiń walidacje stanów magazynowych'}
                />
              </Col>
            )}
            {ownerModuleName && (
              <Col xs={12}>
                <ModuleListPicker
                  disabled={readonly}
                  moduleName={ownerModuleName.configuration.urlPrefix}
                  onChange={item => {
                    get(item['@id']).then(record => {
                      setForm(prevForm => ({
                        ...prevForm,
                        owner: item['@id'],
                        rows: [
                          ...record.rows.map(row => ({
                            _key: uuidv4(),
                            ...DefaultProductRow,
                            product: row.product,
                            unit: row.product?.unit ?? null,
                            quantity: 0,
                          })),
                        ],
                      }));
                    });
                  }}
                  disableClear={true}
                  readerValue={form.owner}
                  defaultFilters={[{ id: 'status', value: '1500' }]}
                  moduleFormComponentProps={undefined}
                  moduleFormComponent={undefined}
                  moduleFormWrapperProps={undefined}
                  allowMultipleSelect={false}
                  onMultipleSelect={console.error}
                  overrideUrl={undefined}
                  headerActionsOnlyLabel={true}
                  disableEditAction={true}
                  disableShowAction={false}
                  disableRemoveAction={true}
                >
                  <RecordInputReader readonly={readonly} value={form?.owner} name="owner" module={ownerModuleName} label={'Zlecenie...'} />
                </ModuleListPicker>
              </Col>
            )}
          </Container>
        </Col>
        {form.stocktaking && (
          <Col xs={12}>
            <Alert className={'text-left'} severity={'info'}>
              Rozpoczynasz inwentaryzację miejsca magazynowego. <br />
              Wszystkie produkty na tym dokumencie muszą zawierać to samo miejsce magazynowe. <br />
              Przed przeprocesowaniem dokumentu, miejsce magazynowe zostanie wyczyszczone.
            </Alert>
          </Col>
        )}
      </Container>
      <Container spacing={2} style={{ marginTop: 20 }}>
        <Col xs={12}>
          <h3 className="text-center">Pozycje na dokumencie</h3>
        </Col>
        <Col xs={12}>
          <ProductListScannerWrapper onAccepted={onAcceptedScannerInput}>
            <Button className="w-100" variant="contained">
              <PhoneAndroid /> Wprowadź za pomocą skanera <PhoneAndroid />
            </Button>
          </ProductListScannerWrapper>
        </Col>
        <Col xs={12}>
          <Paper style={{ overflowX: 'auto' }}>
            <Table style={{ minWidth: 640 }}>
              <TableBody>
                {form.rows.map((item, key) => (
                  <FormProductRow
                    key={item['@id'] ?? item._key ?? `${key}${item.product?.id}`}
                    item={item}
                    index={key}
                    readonly={readonly || (form.status ?? 0) > 1000}
                    onChange={updatedItem => onUpdateItem(key, updatedItem)}
                    onRemove={() => onRemove(key)}
                    warehouse={form.warehouse}
                    placeEvent={form.type.placeEvent}
                  />
                ))}
                {form.rows.length > 0 && (
                  <>
                    <TableRow>
                      <TableCell colSpan={4} />
                      <TableCell colSpan={1} style={{ textAlign: 'right' }}>
                        Wartość netto:
                      </TableCell>
                      <TableCell colSpan={1} style={{ textAlign: 'left' }}>
                        {form.rows.map(el => (el.quantity ?? 1) * el.unitPriceNet).reduce((p, c) => c + p, 0) / 100} {form.currency}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell colSpan={4} />
                      <TableCell colSpan={1} style={{ textAlign: 'right' }}>
                        Wartość VAT:
                      </TableCell>
                      <TableCell colSpan={1} style={{ textAlign: 'left' }}>
                        {form.rows.map(el => (el.quantity ?? 1) * (el.unitPriceGross - el.unitPriceNet)).reduce((p, c) => c + p, 0) / 100}{' '}
                        {form.currency}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell colSpan={4} />
                      <TableCell colSpan={1} style={{ textAlign: 'right' }}>
                        Wartość brutto:
                      </TableCell>
                      <TableCell colSpan={1} style={{ textAlign: 'left' }}>
                        {form.rows.map(el => (el.quantity ?? 1) * el.unitPriceGross).reduce((p, c) => c + p, 0) / 100} {form.currency}
                      </TableCell>
                    </TableRow>
                  </>
                )}
                {!(readonly || (form.status ?? 0) > 1000) && (
                  <>
                    <TableRow>
                      <TableCell
                        colSpan={6}
                        style={{
                          paddingTop: form.rows.length > 0 ? 50 : 5,
                          paddingBottom: 5,
                          textAlign: 'center',
                        }}
                      >
                        <Alert className={'text-center'} severity={'info'}>
                          Aby dodać nową pozycję wybierz produkt poniżej
                        </Alert>
                      </TableCell>
                    </TableRow>
                    <FormProductRow
                      key={form.rows.length + 1}
                      item={{ ...DefaultProductRow }}
                      onChange={onNewItem}
                      warehouse={form.warehouse}
                    />
                  </>
                )}
              </TableBody>
            </Table>
          </Paper>
        </Col>
        {form.statusChangedBy && (
          <Col xs={12}>
            Ostatnia zmiana statusu dokumentu {form.statusChangedBy.firstName} {form.statusChangedBy.lastName} ({form.statusChangedBy.email}
            )
          </Col>
        )}
      </Container>
    </>
  );

  if (loading) {
    return <Loader />;
  }

  return (
    <BaseForm
      title={`Dokument magazynowy [${form.type?.name}] - ${readonly ? 'Podgląd' : !typeId ? 'Edytuj' : 'Dodaj'}`}
      tabs={[
        { title: 'Ogólne', content: DetailsTab() },
        {
          title: 'Zdarzenia magazynowe',
          content: PlaceEventTab({ record: form, field: 'document.id', property: 'id' }),
        },
      ]}
      onSubmit={handleSubmit}
      isSubmitting={isSubmitting}
      readonly={readonly}
      contextActions={
        <>
          <button
            className="btn btn-primary ml-1"
            onClick={() => {
              history.goBack();
            }}
          >
            <i className="mdi mdi-arrow-left" />
            Wróć do listy
          </button>
          {form.id && (
            <>
              <button
                className="btn btn-primary ml-1"
                onClick={() => {
                  console.log(form);
                  history.push(
                    `/warehouse/documents/add/${form.type.id}?warehouse=${form.warehouse?.split('/')?.pop() ?? ''}&copy=${form.id}`,
                  );
                }}
              >
                <i className="mdi mdi-content-copy" /> Kopuj
              </button>
            </>
          )}
          {form.id && (
            <>
              <HistoryModuleButton id={form.id} module={DocumentModule} />
            </>
          )}
          {form.id && (
            <>
              <PrintItemButton
                id={form.number}
                name={form.type.name}
                downloadUrl={`/warehouse/documents/${form.id}`}
                description={'Pobierz plik PDF z dokumentem.'}
                label={true}
              />
              <div className={'ml-1'} style={{ width: '160px', display: 'inline-block', position: 'relative', bottom: '-5px' }}>
                <StatusPicker key={form.id} id={form.id} value={form.status + ''} placeEvent={form.type.placeEvent} />
              </div>
            </>
          )}
        </>
      }
      submitActions={
        <>
          {form.id && form.status === 1000 && (
            <Button
              className={'ml-1'}
              color={'error'}
              style={{ color: 'white' }}
              variant={'contained'}
              type="submit"
              disabled={isSubmitting}
              onClick={handleAccept}
            >
              <i className={isSubmitting ? 'mdi mdi-spin mdi-cogs' : 'mdi mdi-content-save'} />
              &nbsp;Zaakceptuj i wyjdź
            </Button>
          )}
          <Button
            className={'ml-1'}
            color={'warning'}
            variant={'contained'}
            type="submit"
            disabled={isSubmitting}
            onClick={() => handleSubmit(true)}
          >
            <i className={isSubmitting ? 'mdi mdi-spin mdi-cogs' : 'mdi mdi-content-save'} />
            &nbsp;Zapisz i wyjdź
          </Button>
        </>
      }
    />
  );
};

export default withRouter(Form);
