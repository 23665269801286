import { Field } from '@CustomFields/Field';
import FieldTypes from 'components/CustomFields/FieldTypes';
import { FC, memo } from 'react';
import { Col } from 'reactstrap';

const ProvideAllValues = ['TEXT', 'MATH', 'IMAGE'];

type IndexedField = Field & { _tabindex: number };

export type CustomFieldComponentInnerProps<requireValues = boolean, valueType = any> = {
  field: IndexedField;
  fields: IndexedField[];
  value: valueType;
  values?: { [key: string]: any };
  onChange: (val: valueType, fieldId: string) => void;
  readonly: boolean;
  customProps?: any;
  recordId?: string | null;
  errorKey: string;
} & (requireValues extends true ? { values: { [key: string]: any } } : { values?: { [key: string]: any } });

export type CustomFieldComponentType<requireValues = boolean, valueType = any> = FC<
  CustomFieldComponentInnerProps<requireValues, valueType>
> & {
  provideAllValues?: boolean;
};

type CustomFieldComponentProps = Omit<CustomFieldComponentInnerProps, 'onChange'> & {
  fieldTypeComponent: CustomFieldComponentType;
  field: IndexedField;
  fields: IndexedField[];
  onUpdate: (val: any, fieldId: string) => void;
};

const CustomFieldComponent: FC<CustomFieldComponentProps> = props => {
  const { fieldTypeComponent: DynamicComponent, field, fields, value, values, onUpdate, readonly, customProps = {}, recordId } = props;
  return (
    <DynamicComponent
      value={value}
      field={field}
      fields={fields}
      recordId={recordId}
      onChange={(val, fieldId) => (!readonly ? onUpdate(val, fieldId ?? field.id) : false)}
      readonly={readonly}
      errorKey={field.propertyPath}
      customProps={customProps}
      values={values}
    />
  );
};

const CustomFieldComponentMemoized = memo(CustomFieldComponent);
CustomFieldComponentMemoized.displayName = 'CustomFieldComponentMemoized';

type CustomFieldProps = {
  field: IndexedField;
  fields: IndexedField[];
  value: any;
  values: { [key: string]: any };
  onUpdate: CustomFieldComponentProps['onUpdate'];
  readonly: any;
  components: {
    [key: string]: CustomFieldComponentProps['fieldTypeComponent'];
  };
  size: number;
  customProps?: object;
  recordId: string | null;
  disableHideRules?: boolean;
};
const CustomField: FC<CustomFieldProps> = props => {
  const {
    field,
    fields,
    value,
    values,
    onUpdate,
    readonly,
    components = {},
    size = 12,
    customProps = null,
    recordId,
    disableHideRules,
  } = props;
  let hideField = false;

  if (!disableHideRules && field.hideRules && field.hideRules.length > 0) {
    const resolvedConditions = field.hideRules.map(hideRule => {
      const left = values?.[hideRule.field]?.['@id'] ?? values?.[hideRule.field];
      const right = hideRule.value;

      switch (hideRule.cmp) {
        case 'eq':
          return left === right || (!left && !right);
        case 'neq':
          return left !== right;
        case 'like':
          return (left + '').toLowerCase().includes((right + '').toLowerCase());
        case 'nlike':
          return !(left + '').toLowerCase().includes((right + '').toLowerCase());
        default:
          return false;
      }
    });

    hideField = resolvedConditions.filter(el => el).length > 0;
  }

  if (hideField) {
    return null;
  }

  const FieldTypeComponent = components[field.propertyPath] ?? FieldTypes[field.type];
  const fieldDefined = typeof FieldTypeComponent !== 'undefined';
  const includeValuesProps = ProvideAllValues.includes(field.type) || (fieldDefined && FieldTypeComponent?.provideAllValues === true);
  return (
    <>
      <Col xs={size} style={{ height: '100%', padding: '0 6px' }} className="ctm-custom-field">
        {fieldDefined && (
          <CustomFieldComponentMemoized
            fieldTypeComponent={FieldTypeComponent}
            value={value}
            field={field}
            fields={fields}
            recordId={recordId}
            onUpdate={onUpdate}
            readonly={readonly}
            errorKey={field.propertyPath}
            customProps={customProps ?? {}}
            {...(includeValuesProps ? { values: values } : null)}
          />
        )}
        {!fieldDefined && <>Brak obsługi pola typu: {field.type}</>}
      </Col>
    </>
  );
};

export default memo(CustomField);
