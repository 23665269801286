import usePhotoLibrary from '../../Hooks/usePhotoLibrary';
import { Media as MediaItem } from 'Modules/Core/Types/ApiModel';
import Loader from 'components/Theme/Common/Loader';
import { FC } from 'react';

interface PhotoLibraryModuleTabProps {
  onSelect: (file: MediaItem) => void;
}

const PhotoLibraryTab: FC<PhotoLibraryModuleTabProps> = ({ onSelect }) => {
  const { data: images, isLoading } = usePhotoLibrary();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {images.map(image => (
        <div
          key={image.id}
          style={{
            margin: '5px',
            height: '100px',
            width: '100px',
            backgroundImage: `url('${image.thumbnailContentUrl}')`,
            backgroundSize: 'auto',
            backgroundRepeat: 'no-repeat',
            border: '1px solid #ccc',
            cursor: 'pointer',
          }}
          onClick={() => onSelect(image)}
        />
      ))}
    </div>
  );
};

export default PhotoLibraryTab;
