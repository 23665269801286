import { Close, History } from '@mui/icons-material';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Button from '@mui/material/Button';
import Loader from 'components/Theme/Common/Loader';
import { Col, Container } from 'components/Theme/Grid';
import dayjs from 'dayjs';
import { get } from 'helpers/Axios';
import { FC, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Modal, ModalBody } from 'reactstrap';

interface ProductionOrderRowAddModalProps {
  row: { id: string; ['@id']: string; product: { name: string } };
}

const ProductionOrderRowAddModal: FC<ProductionOrderRowAddModalProps> = ({ row }) => {
  const [opened, setOpened] = useState<boolean>(false);
  const {
    isLoading,
    isFetched,
    data: logs,
    refetch,
  } = useQuery(
    row['@id'],
    (): any =>
      get(`/manufacture/production-order-row-logs?orderRow.id=${row.id}&page=1&limit=100&order[createdAt]=DESC`).then(
        res => res['hydra:member'],
      ),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      cacheTime: 1,
    },
  );

  useEffect((): void => {
    refetch();
  }, ['opened']);

  return (
    <>
      <Button variant={'contained'} color={'info'} style={{ width: '100%' }} onClick={(): void => setOpened(true)}>
        <History /> Historia
      </Button>
      <Modal
        isOpen={opened}
        centered={true}
        size={'xl'}
        backdrop={true}
        toggle={() => setOpened(!opened)}
        style={{ maxWidth: 520, maxHeight: '90vw', background: 'transparent' }}
      >
        <ModalBody>
          <Container style={{ position: 'relative', paddingTop: 30 }} spacing={0}>
            <Button style={{ position: 'absolute', top: 10, right: 4 }} onClick={() => setOpened(false)}>
              <Close />
            </Button>
            <Col xs={12}>
              <h3 style={{ textAlign: 'center', padding: 12 }}>Historia {row.product.name}</h3>
            </Col>
            <Col xs={12}>
              {isLoading || !isFetched ? (
                <Loader />
              ) : (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ fontWeight: 'bold' }}>Data</TableCell>
                      <TableCell style={{ fontWeight: 'bold' }}>Pracownik</TableCell>
                      <TableCell style={{ fontWeight: 'bold' }}>Ilość</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {logs.map((item: any, key: number) => (
                      <TableRow key={key}>
                        <TableCell>{dayjs(item?.createdAt).locale('pl').format('LLL')}</TableCell>
                        <TableCell>{item.employee}</TableCell>
                        <TableCell>{item.quantity}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </Col>
          </Container>
        </ModalBody>
      </Modal>
    </>
  );
};
export default ProductionOrderRowAddModal;
