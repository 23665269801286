import { Card } from '@mui/material';
import ProductGroup from 'Modules/Manufacture/ProductGroup';
import ProductType from 'Modules/Manufacture/ProductType';
import classnames from 'classnames';
import { confirmRemove } from 'common/sweetalerts';
import DataGrid from 'components/DataGrid';
import { SelectColumnFilter, SelectModuleRecordColumnFilter } from 'components/DataGrid/Filter';
import ModalFormWrapper from 'components/Form/ModalFormWrapper';
import ModuleForm from 'components/Module/ModuleForm';
import SecuredView from 'components/Theme/Common/SecuredView';
import { useModuleContext } from 'context/ModulesContext';
import dayjs from 'dayjs';
import { post } from 'helpers/Axios';
import useErrorHandler from 'helpers/FormHandler';
import ProductQuantity from 'pages/Manufacture/Product/ListComponents/ProductQuantity';
import RequestForAnOffer from 'pages/Manufacture/ProductDemand/RequestForAnOffer';
import RequestProductDemand from 'pages/Manufacture/ProductDemand/RequestProductDemand';
import { useRef } from 'react';
import { Button, ButtonGroup, CardBody, CardHeader, CardTitle } from 'reactstrap';

const ProductDemandList = () => {
  const module = useModuleContext('manufacture-products');
  const showModal = useRef();
  const errorHandler = useErrorHandler();

  const onRemoveDemand = product => {
    const data = {
      requests: [
        {
          product: product?.['@id'],
          unit: product?.unit?.['@id'],
          quantity: parseFloat(product.demand) * -1,
        },
      ],
      comment: 'Skasowanie zapotrzebowania',
    };

    return post('/manufacture/product-demand-logs', data);
  };

  const DemandColumns = [
    {
      id: 'labelerId',
      filterable: true,
      sortable: true,
      Header: 'ID',
      accessor: 'labelerId',
      width: 100,
    },
    {
      id: 'name',
      filterable: true,
      sortable: true,
      autofocus: true,
      Header: 'Nazwa',
      accessor: 'name',
    },
    {
      id: 'symbol',
      filterable: true,
      sortable: true,
      Header: 'Symbol',
      accessor: 'symbol',
    },
    {
      id: 'ean',
      filterable: true,
      sortable: true,
      Header: 'EAN',
      accessor: 'ean',
    },
    {
      id: 'demand',
      Header: 'Zapotrzebowanie',
      accessor: ({ demand, unit }) => `${demand ?? 0.0} ${unit?.name ?? 'Szt.'}`,
      sortable: true,
    },
    {
      id: 'productQuantity.quantity',
      Header: 'Stan',
      accessor: ({ unit, productQuantity, warehouseQuantities }) => (
        <ProductQuantity
          unit={unit}
          quantity={productQuantity?.quantity ?? 0}
          details={warehouseQuantities.map(el => ({
            name: el.warehouse?.name ?? 'Brak przypisanego magazynu',
            quantity: el.quantity ?? 0,
          }))}
        />
      ),
      sortable: true,
    },
    {
      id: 'productQuantity.reserved',
      Header: 'Rez.',
      accessor: ({ unit, productQuantity, warehouseQuantities }) => (
        <ProductQuantity
          unit={unit}
          quantity={productQuantity?.reserved ?? 0}
          details={warehouseQuantities.map(el => ({
            name: el.warehouse?.name ?? 'Brak przypisanego magazynu',
            quantity: el.reserved ?? 0,
          }))}
        />
      ),
      sortable: true,
    },
    {
      id: 'productQuantity.available',
      Header: 'Dost.',
      accessor: ({ unit, productQuantity, warehouseQuantities }) => (
        <ProductQuantity
          unit={unit}
          quantity={productQuantity?.available ?? 0}
          details={warehouseQuantities.map(el => ({
            name: el.warehouse?.name ?? 'Brak przypisanego magazynu',
            quantity: productQuantity?.available ?? 0,
          }))}
        />
      ),
      sortable: true,
    },
    {
      id: 'price',
      Header: 'Cena kat.',
      accessor: 'price',
      sortable: true,
    },
    {
      id: 'lastSupplyPrice',
      Header: 'Cena z o.d.',
      accessor: 'lastSupplyPrice',
      sortable: true,
    },
    {
      id: 'productQuantity.places',
      Header: 'Miejsca magazynowe',
      accessor: 'productQuantity.places',
      sortable: true,
    },
    {
      id: 'group.id',
      filterable: true,
      sortable: true,
      Header: 'Grupa',
      accessor: 'group.name',
      Filter: SelectModuleRecordColumnFilter,
      filterModule: ProductGroup,
      minWidth: 160,
    },
    {
      id: 'type.id',
      filterable: true,
      sortable: true,
      Header: 'Typ',
      accessor: row => <>{row?.type?.name}</>,
      Filter: SelectModuleRecordColumnFilter,
      filterModule: ProductType,
      minWidth: 160,
    },
    {
      id: 'active',
      Header: 'Aktywny',
      accessor: ({ active }) => {
        return (
          <div className="font-size-20 text-center" style={{ margin: '0 auto' }}>
            <i
              className={classnames('mdi', {
                'mdi-check-circle text-success': active === true,
                'mdi-minus-circle text-danger': active !== true,
              })}
            />
          </div>
        );
      },
      filterable: true,
      Filter: SelectColumnFilter,
      filterOptions: [
        { value: null, label: 'Wszystkie' },
        { value: true, label: 'Tak' },
        { value: '0', label: 'Nie' },
      ],
    },
    {
      id: 'actionsStickyRight',
      Header: () => 'Akcje',
      disableSortBy: true,
      disableFilters: true,
      disableExpandAction: () => true,
      accessor: row => (
        <div className={'actions'}>
          <ButtonGroup>
            {module.configuration.form && (
              <>
                <ModalFormWrapper
                  ref={showModal}
                  title={`${module.configuration.name} - ${module.configuration.recordLabel(row)} - Podgląd`}
                  form={
                    <ModuleForm
                      moduleName={module.configuration.urlPrefix}
                      id={row.id}
                      showContextActions={false}
                      showBackButton={false}
                      showConfigurationSwitcher={false}
                      afterSave={() => {
                        showModal.current.close();
                        listRef.current.refresh();
                      }}
                      onError={errorHandler}
                      readonly={true}
                      overrideFormProps={{ recordCallback: undefined }}
                    />
                  }
                  wrapperComponent={Button}
                  wrapperProps={{ className: 'btn btn-success btn-sm' }}
                >
                  <i className="mdi mdi-eye" style={{ padding: '0 4px' }} />
                </ModalFormWrapper>
                <SecuredView role={`ROLE_REMOVE_${module.configuration.role}`}>
                  <Button
                    onClick={() => {
                      confirmRemove(
                        () => {
                          return new Promise((resolve, reject) => {
                            onRemoveDemand(row)
                              .then(() => {
                                resolve(true);
                              })
                              .catch(reject);
                          });
                        },
                        'Czy jesteś pewien?',
                        'Czy jesteś pewien że chcesz skasować zapotrzebowanie na ten produkt?',
                        true,
                        'Zapotrzebowanie zostanie za chwilę usunięte',
                      );
                    }}
                    className="btn btn-danger  btn-sm"
                  >
                    <i className="mdi mdi-delete" style={{ padding: '0 4px' }} />
                  </Button>
                </SecuredView>
              </>
            )}
          </ButtonGroup>
        </div>
      ),
    },
  ];

  const DemandLogColumns = [
    {
      id: 'product.labelerId',
      filterable: true,
      sortable: true,
      Header: 'ID',
      accessor: 'product.labelerId',
      width: 100,
    },
    {
      id: 'product.name',
      filterable: true,
      sortable: true,
      autofocus: true,
      Header: 'Nazwa',
      accessor: 'product.name',
    },
    {
      id: 'product.symbol',
      filterable: true,
      sortable: true,
      Header: 'Symbol',
      accessor: 'product.symbol',
    },
    {
      id: 'product.ean',
      filterable: true,
      sortable: true,
      Header: 'EAN',
      accessor: 'product.ean',
    },
    {
      id: 'requestedAt',
      Header: 'Data zmiany',
      accessor: ({ requestedAt }) => (requestedAt ? dayjs(requestedAt).locale('pl').format('LLL') : null),
      filterable: false,
      sortable: true,
      flex: 1,
    },
    {
      id: 'change',
      Header: 'Zmiana',
      accessor: ({ change, product, unit }) => `${change >= 0 ? '+' : ''}${change ?? 0.0} ${unit?.name ?? product?.unit?.name ?? 'Szt.'}`,
      sortable: true,
    },
    {
      id: 'comment',
      filterable: true,
      sortable: true,
      Header: 'Komentarz',
      accessor: 'comment',
    },
  ];

  return (
    <>
      <div style={{ marginBottom: 24, width: '100%' }}>
        <RequestProductDemand />
      </div>
      <Card>
        <CardHeader>
          <CardTitle tag="h5" className="text-center p-3">
            Lista towarów z zapotrzebowaniem
          </CardTitle>
        </CardHeader>
        <CardBody style={{ padding: 0 }}>
          <DataGrid
            url={'/manufacture/products?demand[gt]=0'}
            fullWidth={false}
            responsive={true}
            className={'table-clickable'}
            columns={DemandColumns}
            defaultOrderBy={[]}
            queryKey={'products-demand-list'}
            storeFilters={true}
            selectionActions={RequestForAnOffer}
          />
        </CardBody>
        <CardHeader>
          <CardTitle tag="h5" className="text-center p-3">
            Historia zapotrzebowania
          </CardTitle>
        </CardHeader>
        <CardBody style={{ padding: 0 }}>
          <DataGrid
            url={'/manufacture/product-demand-logs'}
            fullWidth={false}
            responsive={true}
            className={'table-clickable'}
            columns={DemandLogColumns}
            defaultOrderBy={[{ id: 'requestedAt', desc: true }]}
            queryKey={'products-demand-log'}
            storeFilters={true}
          />
        </CardBody>
      </Card>
    </>
  );
};

export default ProductDemandList;
