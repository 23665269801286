import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { ProductTypeModule, WarehouseModule } from 'Modules/CTMModules';
import ListPicker from 'components/Form/ListPicker';
import BaseForm from 'components/Theme/Common/BaseForm';
import Loader from 'components/Theme/Common/Loader';
import { Col, Container } from 'components/Theme/Grid';
import { post, postRawData } from 'helpers/Axios';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, FormGroup, Input, InputGroup, InputGroupText, Label } from 'reactstrap';
import { useAppSelector } from 'store';
import { addSingleToast } from 'store/Toast/actions';

const ImportPreview = ({ response }) => (
  <>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>ID ECM</TableCell>
          <TableCell>Symbol</TableCell>
          <TableCell>Nazwa</TableCell>
          <TableCell>Miejsce magazynowe</TableCell>
          <TableCell>Stan w systemie</TableCell>
          <TableCell>Stan w pliku</TableCell>
          <TableCell>Różnica stanu</TableCell>
          <TableCell>Cena w systemie</TableCell>
          <TableCell>Cena w pliku</TableCell>
          <TableCell>Różnica ceny</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {response.rows.map((item, index) => (
          <TableRow key={index}>
            <TableCell>{item.product.labelerId}</TableCell>
            <TableCell>{item.product.symbol}</TableCell>
            <TableCell>{item.product.name}</TableCell>
            <TableCell>{item.place.name}</TableCell>
            <TableCell>{item.quantity}</TableCell>
            <TableCell>{item.quantityFile}</TableCell>
            <TableCell>{item.quantityDifference}</TableCell>
            <TableCell>{item.price}</TableCell>
            <TableCell>{item.priceFile}</TableCell>
            <TableCell>{item.priceDifference}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
    <h4 className={'mt-2 mb-2'}>Błędy importu:</h4>
    {response.errors.map(item => (
      <>
        {item} <br />
      </>
    ))}
  </>
);

const ImportStore = () => {
  const [loading, setLoading] = useState(true);
  const [warehouse, setWarehouse] = useState(null);
  const [productType, setProductType] = useState(null);
  const [warehouses, setWarehouses] = useState([]);
  const [productTypes, setProductTypes] = useState([]);

  const resolveWarehouses = () => {
    setLoading(true);
    WarehouseModule.api.getAll().then(warehouses => {
      setWarehouses(warehouses['hydra:member']);
    });
    setLoading(false);
  };

  const resolveProductTypes = () => {
    setLoading(true);
    ProductTypeModule.api.getAll().then(productTypes => {
      setProductTypes(productTypes['hydra:member']);
    });
    setLoading(false);
  };

  const dispatch = useDispatch();

  const [response, setResponse] = useState(null);

  const history = useHistory();
  const htmlInput = useRef(null);
  const { violations } = useAppSelector(state => ({
    violations: state.FormErrors.violations,
  }));

  const hasError = () => {
    return violations.hasOwnProperty('file');
  };

  const handleAcceptedFiles = files => {
    setLoading(true);
    const file = files[0];
    let data = new FormData();
    data.append('file', file);
    data.append('warehouse', warehouse);
    data.append('productType', productType);

    postRawData('/warehouse/import/store', data)
      .then(response => {
        setResponse(response);
        setLoading(false);
        dispatch(
          addSingleToast({
            title: 'Plik został poprawnie załadowany. Sprawdź wartości!',
            config: { appearance: 'success' },
          }),
        );
      })
      .catch(error => {
        setLoading(false);
        console.error(error);
      });
  };

  const handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      handleAcceptedFiles(e.dataTransfer.files);
      e.dataTransfer.clearData();
    }
  };

  const onSubmit = () => {
    setLoading(true);

    const rows = response.rows.map(item => {
      delete item['@id'];
      return { ...item, product: item.product['@id'], place: item.place['@id'] };
    });

    post('/warehouse/import/store/process', { rows: rows })
      .then(() => {
        setResponse(null);
        setLoading(false);
        dispatch(
          addSingleToast({
            title: 'Ceny zostały załadowane. Dokumenty zmieniające stany magazynowe zostały przygotowane i oczekują na akceptację.',
            config: { appearance: 'success' },
          }),
        );
      })
      .catch(error => {
        setLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    resolveWarehouses();
    resolveProductTypes();
  }, []);

  const DetailsTab = () => (
    <>
      <Container justifyContent="space-between">
        <Col xs={3} md={3} lg={3}>
          <ListPicker
            disableGroupMargin={true}
            value={warehouse}
            name={'warehouse'}
            label={'Magazyn docelowy'}
            onChange={value => {
              setWarehouse(value);
            }}
            options={warehouses}
            isClearable={false}
            style={{ width: '200px', display: 'inline-block' }}
            className={'ml-1 mb-1'}
          />
        </Col>
        <Col xs={3} md={3} lg={3}>
          <ListPicker
            disableGroupMargin={true}
            value={productType}
            name={'productType'}
            label={'Typ produktu'}
            onChange={value => {
              setProductType(value);
            }}
            optionLabel={item => (
              <>
                <span className="badge text-white font-size-12" style={{ padding: 6, background: `${item.color || '#edf0f5'}` }}>
                  <i className={`mdi mdi-${item?.icon}`} />
                </span>
                &nbsp;{item.name}
              </>
            )}
            options={productTypes}
            isClearable={false}
            style={{ width: '200px', display: 'inline-block' }}
            className={'ml-1 mb-1'}
          />
        </Col>
        <Col xs={3} md={3} lg={3}></Col>
        <Col xs={3} md={3} lg={3}>
          {warehouse && productType && (
            <FormGroup>
              <Label>Plik importu</Label>
              <InputGroup>
                <InputGroupText style={{ cursor: 'pointer' }} onClick={() => htmlInput.current.click()} onDrop={handleDrop}>
                  Wybierz plik
                </InputGroupText>
                <Input
                  className="form-control d-none"
                  type="file"
                  innerRef={htmlInput}
                  invalid={hasError()}
                  onChange={e => handleAcceptedFiles(e.target.files, name)}
                  autoComplete={'off'}
                  name={'file'}
                  disabled={false}
                />
              </InputGroup>
            </FormGroup>
          )}
        </Col>
        {response && (
          <>
            <Col xs={12} md={12} lg={12}>
              <ImportPreview response={response} />
              <div className={'align-self-center justify-content-end text-end mt-1'}>
                <Button onClick={() => onSubmit()} className={'btn btn-info mt-2'}>
                  <i className="bx bx-add-to-queue" /> Importuj
                </Button>
              </div>
            </Col>
          </>
        )}
      </Container>
    </>
  );

  if (loading) {
    return (
      <div className="container-fluid">
        <Loader />
      </div>
    );
  }

  return (
    <div className="container-fluid">
      <BaseForm
        title={`Import stanów i cen`}
        tabs={[{ title: 'Ogólne', content: <DetailsTab /> }]}
        readonly={true}
        contextActions={
          <>
            <button
              className="btn btn-primary ml-1"
              onClick={() => {
                history.goBack();
              }}
            >
              <i className="mdi mdi-arrow-left" />
              Wróć do listy
            </button>
          </>
        }
      />
    </div>
  );
};

export default ImportStore;
