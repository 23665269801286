import { CustomFieldComponentType } from '@Components/CustomFields/CustomField';
import { FieldType } from '@Components/CustomFields/FieldTypes';
import TextInput from '@Components/Form/MUI/TextInput';
import Loader from '@Components/Theme/Common/Loader';
import { Col, Container } from '@Components/Theme/Grid';
import { ExportConfigField } from '@Core/ExportConfig';
import useModuleData from '@Core/Hooks/useModuleData';
import { Field } from '@CustomFields/Field';
import { dataClassModulesMap } from '@Modules/CTMModules';
import { DeleteForever } from '@mui/icons-material';
import { Alert, Button, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { FC, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';

type TabConfiguration = {
  types: FieldType[];
  label: string;
  val: TabTypes[FieldType];
};

type TabTypes = { [key: string]: number };
const tabTypes: TabTypes = {
  TEXT: 0,
  NUMBER: 1,
  RELATION: 2,
  IMAGE: 3,
  FILE: 4,
  READONLY: 5,
};

const TABS: TabConfiguration[] = [
  {
    val: tabTypes['TEXT'],
    types: ['TEXT', 'STRING', 'PASSWORD', 'YOU_TUBE', 'COLOR', 'COUNTRY', 'RELATION'],
    label: 'Tekstowe',
  },
  {
    val: tabTypes['NUMBER'],
    types: ['NUMBER', 'MATH'],
    label: 'Numeryczne',
  },
  {
    val: tabTypes['RELATION'],
    types: ['RELATION'],
    label: 'Relacje',
  },
  {
    val: tabTypes['IMAGE'],
    types: ['IMAGE'],
    label: 'Obrazki',
  },
  {
    val: tabTypes['FILE'],
    types: ['FILE'],
    label: 'Pliki',
  },
  {
    val: tabTypes['READONLY'],
    types: ['READONLY'],
    label: 'Tylko do odczytu',
  },
];

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

const getNamePathForField = (exportFieldConfig: ExportConfigField): string => {
  let namePath = '';
  if (typeof exportFieldConfig.field !== 'string') {
    namePath = exportFieldConfig.field.name || 'Nazwa pola';
    namePath += ` (${exportFieldConfig.field.labelerId})`;
  }

  if (exportFieldConfig.exportFieldConfig) {
    namePath += ' -> ' + getNamePathForField(exportFieldConfig.exportFieldConfig);
  }

  return namePath;
};
const getIdPathForField = (exportFieldConfig: ExportConfigField): string => {
  let idPath = '';
  if (typeof exportFieldConfig.field !== 'string') {
    idPath = exportFieldConfig.field?.id;
  }

  if (exportFieldConfig.exportFieldConfig) {
    idPath += '.' + getIdPathForField(exportFieldConfig.exportFieldConfig);
  }

  return idPath;
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

interface FieldsPanelProps {
  fields: Field[];
  types: FieldType[];
  pathPrefix?: string;
  selectedPaths: string[];
  onSelect: (exportConfigField: ExportConfigField) => void;
}
const FieldsPanel: FC<FieldsPanelProps> = ({ fields, types, onSelect, pathPrefix, selectedPaths }) => {
  const [value, setValue] = useState<number>(0);
  const [searchFieldsText, setSearchFieldsText] = useState<string>('');
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const isRelations = types.length === 1 && types.includes('RELATION');
  const filteredFields: Field[] = useMemo(
    () =>
      fields.filter(el => {
        return (
          (searchFieldsText.length === 0 || `${el.name} ${el.labelerId}`.toLowerCase().includes(searchFieldsText.toLowerCase())) &&
          types.includes(el.type) &&
          (!isRelations || !selectedPaths.includes((pathPrefix ? pathPrefix + '.' : '') + getIdPathForField({ field: el, name: '' })))
        );
      }),
    [searchFieldsText],
  );

  if (isRelations && filteredFields.length === 0) {
    return <></>;
  }

  const handleFieldSelected = (field: Field) => {
    onSelect({
      field: field,
      name: `${field.name || 'Nazwa Pola'} (${field.labelerId})`,
    });
  };
  const handleNestedFieldPick = (field: Field, exportFieldConfig: ExportConfigField) => {
    onSelect({
      field: field,
      name: `${field.name || 'Nazwa Pola'} (${field.labelerId})`,
      exportFieldConfig: exportFieldConfig,
    });
  };
  return (
    <Container style={{ border: '1px solid #cbcbcb', borderRadius: 10 }}>
      <Col xs={12}>
        <div>
          {isRelations && (
            <>
              <AppBar position="static" style={{ backgroundColor: '#56b006' }}>
                <Tabs value={value} onChange={handleChange} textColor="inherit" variant="scrollable" scrollButtons="auto">
                  {filteredFields.map(el => (
                    <Tab key={el['@id']} label={el.name || 'Nazwa pola'} />
                  ))}
                </Tabs>
              </AppBar>
              {filteredFields.map((el, index) => (
                <TabPanel key={el['@id']} value={value} index={index}>
                  <ModuleFieldsPicker
                    pathPrefix={`${pathPrefix ? pathPrefix + '.' : ''}${el.id}`}
                    moduleId={dataClassModulesMap[el.config.targetModule]}
                    selectedPaths={selectedPaths}
                    onFieldPick={exportConfigField => handleNestedFieldPick(el, exportConfigField)}
                  />
                </TabPanel>
              ))}
            </>
          )}
          {!isRelations && filteredFields.length > 0 && (
            <>
              <TextInput value={searchFieldsText} label="" onChange={val => setSearchFieldsText(val ?? '')} placeholder="Szukaj..." />
              {filteredFields.map(el => (
                <Button key={el['@id']} variant="contained" style={{ margin: 5 }} onClick={() => handleFieldSelected(el)}>
                  {el.name || 'Nazwa pola'} ({el.labelerId})
                </Button>
              ))}
            </>
          )}
          {!isRelations && filteredFields.length === 0 && (
            <>
              <Alert color="warning">Brak pól</Alert>
            </>
          )}
        </div>
      </Col>
    </Container>
  );
};

interface ModuleFieldsPickerProps {
  moduleId: string;
  pathPrefix?: string;
  onFieldPick: (exportConfigField: ExportConfigField) => void;
  selectedPaths: string[];
}

const ModuleFieldsPicker: FC<ModuleFieldsPickerProps> = ({ moduleId, onFieldPick, pathPrefix, selectedPaths }) => {
  const { data: moduleData, isLoading } = useModuleData(moduleId);
  const [value, setValue] = useState<number>(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  if (isLoading) {
    return <Loader />;
  }

  const fields: Field[] = moduleData.fields;

  return (
    <>
      <Box sx={{ bgcolor: 'background.paper' }}>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="scrollable"
            scrollButtons="auto"
          >
            {TABS.map(el => (
              <Tab key={el.val} label={el.label} />
            ))}
          </Tabs>
        </AppBar>
        {TABS.map(el => (
          <TabPanel key={el.val} value={value} index={el.val}>
            <FieldsPanel fields={fields} types={el.types} onSelect={onFieldPick} pathPrefix={pathPrefix} selectedPaths={selectedPaths} />
          </TabPanel>
        ))}
      </Box>
    </>
  );
};

const ExportConfigFields: CustomFieldComponentType<false, ExportConfigField[]> = props => {
  const { field, fields, onChange, values, value } = props;
  const [selectedFields, setSelectedFields] = useState<ExportConfigField[]>(value || []);
  const moduleFieldId = fields.find(el => el.propertyPath === 'module')?.id ?? '';

  const selectedModule = values?.[moduleFieldId]?.id;

  const handleFieldPick = useCallback((exportFieldConfig: ExportConfigField) => {
    setSelectedFields(prev => {
      return [...prev, { ...exportFieldConfig, name: `${prev.length + 1}. ${exportFieldConfig.name}` }];
    });
  }, []);
  const handleNameChange = useCallback((index: number, val?: string) => {
    setSelectedFields(prev => {
      const newState = prev.map((el, i) => {
        if (i === index) {
          return { ...el, name: val ?? '' };
        }
        return el;
      });
      onChange(newState, field.id);

      return newState;
    });
  }, []);
  const removeFieldByIndex = useCallback((index: number) => {
    setSelectedFields(prev => {
      return prev.filter((_, idx) => idx !== index);
    });
  }, []);

  useEffect(() => {
    if (JSON.stringify(selectedFields) !== JSON.stringify(value)) {
      onChange(selectedFields, field.id);
    }
  }, [selectedFields.length, onChange]);

  const selectedPaths = useMemo(() => {
    return selectedFields.map(getIdPathForField);
  }, [selectedFields.length]);

  if (!selectedModule) {
    return <Alert color="warning">Sekcja z wyborem pól będzie dostępna po wybraniu modułu.</Alert>;
  }

  return (
    <Container style={{ marginTop: 20 }}>
      <Col xs={12} lg={4}>
        <Typography>Wybrane pola:</Typography>
        <Table className="export-form__table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Nazwa</TableCell>
              <TableCell>Ścieżka</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>0</TableCell>
              <TableCell>ID</TableCell>
              <TableCell>ID (pole stałe)</TableCell>
            </TableRow>
            {selectedFields.map((selectedField, index) => (
              <TableRow key={`TableRow_SelectedField_${index + 1}`} className="export-form__row">
                <TableCell className="indexer__col">
                  <div className="indexer__col--number">{index + 1}</div>
                  <div className="indexer__col--icon">
                    <DeleteForever color="error" onClick={() => removeFieldByIndex(index)} />
                  </div>
                </TableCell>
                <TableCell>
                  <TextInput value={selectedField.name} label="" onChange={val => handleNameChange(index, val ?? '')} />
                </TableCell>
                <TableCell>{getNamePathForField(selectedField)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Col>
      <Col xs={12} lg={8}>
        <Typography>Wybierz kolejne pola:</Typography>
        <ModuleFieldsPicker moduleId={selectedModule} onFieldPick={handleFieldPick} selectedPaths={selectedPaths} />
      </Col>
    </Container>
  );
};

ExportConfigFields.provideAllValues = true;

export default ExportConfigFields;
