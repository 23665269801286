import { get } from '../../../helpers/Axios';
import { Paper } from '@mui/material';
import { CoreModuleModule, TemplateModule } from 'Modules/CTMModules';
import TemplateSelector from 'Modules/CustomFields/CoreComponents/TemplateSelector';
import TemplateSelectorSelect from 'Modules/CustomFields/ModalComponents/TemplateSelector';
import ModuleTemplatesBuilder from 'Modules/CustomFields/ModuleTemplatesBuilder';
import classnames from 'classnames';
import CustomTab from 'components/CustomFields/CustomTab';
import Loader from 'components/Theme/Common/Loader';
import SecuredView from 'components/Theme/Common/SecuredView';
import { setValue } from 'helpers/PropertyAccessor';
import { Fragment, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSearchParam } from 'react-use';
import { Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { useAppSelector } from 'store';
import { clearErrors } from 'store/form/errors/actions';

const TabNavLink = ({ onDoubleClick, onClick, tab, activeTab, fields }) => {
  const { violations } = useAppSelector(
    state => ({
      violations: state.FormErrors.violations,
    }),
    shallowEqual,
  );

  const hasTabError = useMemo(() => {
    return []
      .concat(...tab.sections.map(s => s.layout))
      .find(
        layoutRow =>
          violations.hasOwnProperty(fields.find(el => el.id === layoutRow.i)?.propertyPath) || violations.hasOwnProperty(layoutRow.i),
      );
  }, [tab, violations, fields]);

  return (
    <NavItem key={`NavItem_${tab.id}`}>
      <NavLink
        style={{ cursor: 'pointer' }}
        className={classnames({
          active: activeTab === tab.id,
          'dark-accent-color': activeTab !== tab.id,
        })}
        onClick={onClick}
      >
        <span
          onDoubleClick={onDoubleClick}
          style={{
            position: 'relative',
            paddingRight: hasTabError ? 20 : 0,
          }}
        >
          {hasTabError && (
            <i
              className="mdi mdi-alert-circle text-danger font-size-22"
              style={{
                position: 'absolute',
                right: -10,
                top: -6,
              }}
            />
          )}
          {tab.name || 'Nazwa zakładki'}
        </span>
      </NavLink>
    </NavItem>
  );
};
const ApiForm = forwardRef(
  (
    {
      onSubmit = () => console.error('You have to implement onSubmit prop'),
      readonly = undefined,
      contextActions = () => undefined,
      submitActions = () => undefined,
      inlineComponentAppendHeader = () => undefined,
      components = {},
      recordLabel = undefined,
      showHeader = true,
      dataClass = '',
      customTabs = [],
      recordCallback = async () => ({ '@formValues': {} }),
      showConfigurationSwitcher = true,
      showBackButton = true,
      backUrl,
      moduleId,
      forceReadonlyField,
      onRecordChange,
      autoSave,
      resetTab = false,
      trackTabInUrl = true,
    },
    ref,
  ) => {
    const [activeTab, setActiveTab] = useState(null);
    const [tabs, setTabs] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [template, setTemplate] = useState(null);
    const [record, setRecord] = useState(null);
    const [originalRecord, setOriginalRecord] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [autoSaveTimeoutId, setAutoSaveTimeoutId] = useState(null);
    const [savedState, setSavedState] = useState({});
    const dispatch = useDispatch();
    const history = useHistory();
    const selectedLanguage = useAppSelector(state => state.Root.language);
    const templateId = useSearchParam('templateId');

    const [module, setModule] = useState(null);
    const moduleTemplatesBuilder = useRef();
    // module.fields
    const onRecordChangeMap = (recordNewState, recordOldState) => {
      if (!onRecordChange) {
        return recordNewState;
      }

      let newTmpState = onRecordChange(recordNewState, recordOldState, originalRecord, module, setRecord);
      if (JSON.stringify(newTmpState) === JSON.stringify(recordNewState)) {
        newTmpState = recordNewState;
      }

      return newTmpState;
    };
    useImperativeHandle(ref, () => ({
      overrideValues(overriddenValues) {
        if (!overriddenValues.length) {
          return;
        }
        setRecord(prevRecord => {
          let changes = {};

          overriddenValues.map(item => {
            const fieldConfiguration = module.fields.find(el => el.propertyPath === item.field);
            if (fieldConfiguration) {
              changes = {
                ...changes,
                '@formValues': {
                  ...(record['@formValues'] ?? {}),
                  ...(changes['@formValues'] ?? {}),
                  [fieldConfiguration.id]: item.value,
                },
                ...(fieldConfiguration.system ? setSystemFieldValue(fieldConfiguration.propertyPath, item.value, prevRecord) : {}),
              };
            }
          });

          const computedNewRecord = { ...prevRecord, ...changes };
          return computedNewRecord;
        });
      },
      setRecord(newRecord) {
        setRecord(prevRecord => {
          const computedNewRecord = { ...prevRecord, ...newRecord };
          return computedNewRecord;
        });
      },
      getRecord() {
        return { ...record, '@template': template?.['@id'] };
      },
      async refresh() {
        await fetchRecord(false);
      },
    }));

    useEffect(() => {
      if (trackTabInUrl) {
        const urlSearchParams = new URLSearchParams(location.search);
        const tab = urlSearchParams.get('tab');

        if (tab && !resetTab) {
          toggle(/^\d+$/.test(tab) ? parseInt(tab) : tab);
        }
      }
    }, []);

    const toggle = useCallback(
      tab => {
        if (activeTab !== tab) {
          setActiveTab(tab);
          if (trackTabInUrl) {
            addParameterToUrl(tab);
          }
        }
      },
      [activeTab, trackTabInUrl],
    );

    const addParameterToUrl = tab => {
      const currentUrl = new URL(window.location.href);
      if (currentUrl.searchParams.has('tab')) {
        currentUrl.searchParams.set('tab', tab);
      } else {
        currentUrl.searchParams.append('tab', tab);
      }
      history.push(currentUrl.pathname + currentUrl.search);
    };

    const fetchRecord = useCallback(
      async (clearExistingErrors = false) => {
        if (clearExistingErrors) {
          dispatch(clearErrors());
        }
        const fetchedRecord = await recordCallback(module?.fields ?? []);
        if (fetchedRecord.hasOwnProperty('@id')) {
          if (fetchedRecord.hasOwnProperty('@template') && fetchedRecord['@template'] !== null) {
            setTemplate(fetchedRecord['@template']);
          } else {
            setTemplate(templates.find(tmpTemplate => tmpTemplate.system) ?? null);
          }
          setRecord(fetchedRecord);
          setSavedState({ ...fetchedRecord, '@template': fetchedRecord?.['@template']?.['@id'] });
          setOriginalRecord(fetchedRecord);
        } else {
          let defaultRecord = {};
          if (template?.id) {
            defaultRecord = await get(TemplateModule.configuration.api.item.getRecord({ id: template?.id }));
          }

          const newRecordState = {
            ...defaultRecord,
            ...fetchedRecord,
            '@formValues': {
              ...defaultRecord['@formValues'],
              ...fetchedRecord['@formValues'],
            },
          };
          setRecord(newRecordState);
          setOriginalRecord(newRecordState);
        }
      },
      [module?.fields, recordCallback, dispatch, template?.id],
    );

    const fetchTemplateTabs = useCallback(async () => {
      if (template?.id) {
        TemplateModule.api.get({ id: template?.id }).then(async res => {
          setTabs(res.tabs);
          const defaultRecord = await get(TemplateModule.configuration.api.item.getRecord({ id: template?.id }));
          setRecord(prevRecord => {
            if (prevRecord && !prevRecord.hasOwnProperty('@id')) {
              const computedNewRecord = {
                ...defaultRecord,
                ...prevRecord,
                '@formValues': {
                  ...(defaultRecord?.['@formValues'] ?? {}),
                  ...(prevRecord?.['@formValues'] ?? {}),
                },
              };
              return computedNewRecord;
            }

            return prevRecord;
          });
        });
      }
    }, [template?.id]);

    const setSystemFieldValue = useCallback((propertyPath, value, prevRecord) => {
      const key = propertyPath.split('.');

      if (key.length < 2) {
        return {
          [propertyPath]: value,
        };
      }

      let object = { [key[0]]: prevRecord[key[0]] } || {};
      setValue(object, propertyPath, value);

      return object;
    }, []);

    useEffect(() => {
      if (tabs?.length > 0 && (activeTab === null || ('' + activeTab.length >= 10 && !tabs.map(el => el.id).includes(activeTab)))) {
        toggle(tabs[0]?.id);
      }
    }, [tabs, activeTab, toggle]);

    useEffect(() => {
      if (template?.id) {
        fetchTemplateTabs();
      }
    }, [template]);

    const fetchModule = id => {
      CoreModuleModule.api.get({ id: id }).then(res => {
        setModule(res);
        setTemplates(res.templates);
        if (!template || !res.templates.map(el => el['@id']).includes(template?.['@id'])) {
          if (res.templates.length === 1) {
            setTemplate(res.templates[0]);
          } else if (templateId) {
            const templateToSet = res.templates.find(tmpTemplate => tmpTemplate.id === templateId);
            if (templateToSet) {
              setTemplate(templateToSet);
            }
          }
        }
      });
    };

    useEffect(() => {
      if (moduleId) {
        fetchModule(moduleId);
      }
    }, [moduleId]);

    useEffect(() => {
      if (autoSave && autoSave > 0) {
        if (autoSaveTimeoutId) {
          clearTimeout(autoSaveTimeoutId);
        }
        const timeoutId = setTimeout(async () => {
          const data = { ...record, '@template': template?.['@id'] };
          if (JSON.stringify(data) !== JSON.stringify(savedState)) {
            setSavedState(data);
            const statusOfSave = await onSubmit(data, module?.fields ?? []);
            if (statusOfSave) {
              await fetchRecord(false);
            }
          }
        }, autoSave);
        setAutoSaveTimeoutId(timeoutId);
      }
    }, [autoSave, record, template, savedState]);

    const loadRecord = useCallback(async () => {
      if (null === record) {
        await fetchRecord(true);
      }
    }, [record, fetchRecord]);

    useEffect(() => {
      if (module?.id) {
        loadRecord();
      }
    }, [module, loadRecord]);

    const handleChangeTemplate = newTemplate => {
      const newTemplateObject = templates.find(tmpTemplate => tmpTemplate.id === newTemplate);
      if (newTemplateObject) {
        setTemplate(newTemplateObject);
      }
    };
    const handleSubmit = async e => {
      setIsSubmitting(true);
      e.preventDefault();
      e.stopPropagation();
      dispatch(clearErrors());
      if (autoSaveTimeoutId) {
        clearTimeout(autoSaveTimeoutId);
      }
      try {
        const data = { ...record, '@template': template?.['@id'] };
        setSavedState(data);
        const statusOfSave = await onSubmit(data, module?.fields ?? []);
        if (statusOfSave) {
          await fetchRecord(false);
        }
      } catch (e) {
        console.error(e);
      }
      setIsSubmitting(false);
    };

    const updateCustomFormValue = useCallback(
      (value, field) => {
        const fieldConfiguration = module.fields.find(el => el.id === field);
        setRecord(prevRecord => {
          let computedNewRecord;
          if (fieldConfiguration.multiLanguage) {
            computedNewRecord = {
              ...prevRecord,
              '@formValues': {
                ...prevRecord['@formValues'],
                [field]: {
                  '@ContentTranslation': true,
                  value: {
                    ...prevRecord['@formValues']?.[field]?.value,
                    [selectedLanguage]: value,
                  },
                },
              },
              ...(fieldConfiguration.system ? setSystemFieldValue(fieldConfiguration.propertyPath, value, prevRecord) : {}),
            };
          } else {
            computedNewRecord = {
              ...prevRecord,
              '@formValues': { ...prevRecord['@formValues'], [field]: value },
              ...(fieldConfiguration.system ? setSystemFieldValue(fieldConfiguration.propertyPath, value, prevRecord) : {}),
            };
          }
          return onRecordChangeMap(computedNewRecord, prevRecord);
        });
      },
      [module?.fields, setSystemFieldValue, selectedLanguage],
    );

    const updateRecord = useCallback(
      newRecord => {
        if (typeof newRecord === 'function') {
          setRecord(prevRecord => {
            const computedNewRecord = newRecord(prevRecord, module?.fields ?? []);
            return onRecordChangeMap(computedNewRecord, prevRecord);
          });
        } else {
          setRecord(prevRecord => {
            const computedNewRecord = { ...prevRecord, ...newRecord };
            return onRecordChangeMap(computedNewRecord, prevRecord);
          });
        }
      },
      [JSON.stringify(module?.fields)],
    );

    if (!template && module?.templates.length > 1 && record) {
      return <TemplateSelector templates={module.templates} onSelectTemplate={setTemplate} />;
    }

    if (!record || !module) {
      return <Loader />;
    }

    const tabsToDisplay = tabs.filter(tabToCheck => {
      let hideTab = false;

      if (tabToCheck.hideRules && tabToCheck.hideRules.length > 0) {
        const resolvedConditions = tabToCheck.hideRules.map(hideRule => {
          const left = record['@formValues']?.[hideRule.field]?.['@id'] ?? record['@formValues']?.[hideRule.field];
          const right = hideRule.value;

          switch (hideRule.cmp) {
            case 'eq':
              return left === right || (!left && !right);
            case 'neq':
              return left !== right;
            case 'like':
              return (left + '').toLowerCase().includes((right + '').toLowerCase());
            case 'nlike':
              return !(left + '').toLowerCase().includes((right + '').toLowerCase());
            default:
              return false;
          }
        });

        hideTab = resolvedConditions.filter(el => el).length === tabToCheck.hideRules.length;
      }

      return !hideTab;
    });

    const allowedCustomTabs = customTabs.filter(ct => !ct.isAllowedToDisplay || ct.isAllowedToDisplay(record));

    return (
      <Container fluid={true} className={classnames('api-form', { 'short-header': readonly && !showConfigurationSwitcher })}>
        {showHeader && (
          <Row className={classnames('mb-2 form-sticky-header')}>
            <Col xs={12} md={6} className="align-self-center">
              <h4>
                {module.name} {recordLabel && recordLabel(record, module.fields) ? `- ${recordLabel(record, module.fields)}` : ''} -{' '}
                {readonly ? 'Podgląd' : record['@id'] ? 'Edytuj' : 'Dodaj'}
              </h4>
            </Col>
            <Col xs={12} md={6} className="align-self-center justify-content-end mb-md-2 text-end">
              {contextActions(record)}
            </Col>
            <Col xs={12} md={3} className="align-self-center d-flex">
              {showConfigurationSwitcher && (
                <div>
                  <SecuredView role="ROLE_EDIT_CONFIGURATION">
                    <ModuleTemplatesBuilder
                      ref={moduleTemplatesBuilder}
                      moduleId={module.id}
                      templateId={template?.id ?? null}
                      afterSave={() => {
                        fetchTemplateTabs();
                        fetchModule(moduleId);
                      }}
                      wrapperProps={{ style: { display: 'inline-block' } }}
                    >
                      <button className="btn btn-primary" onClick={() => moduleTemplatesBuilder.current.open()}>
                        <i className={'mdi mdi-cogs'} /> {readonly && 'Tryb konfiguracji pól'}
                      </button>
                    </ModuleTemplatesBuilder>
                  </SecuredView>
                </div>
              )}
              {!readonly && (
                <div style={{ width: '100%', paddingLeft: 6 }}>
                  <TemplateSelectorSelect
                    loading={false}
                    templates={templates}
                    selectedTemplate={template?.id ?? null}
                    handleChangeTemplate={handleChangeTemplate}
                    wrapperComponent={Fragment}
                  />
                </div>
              )}
            </Col>
            <Col xs={12} md={9} className="text-end">
              {showBackButton && (
                <button
                  className="btn btn-primary ml-1"
                  onClick={() => {
                    if (backUrl) {
                      history.push(backUrl);
                    } else {
                      history.goBack();
                    }
                  }}
                >
                  <i className="mdi mdi-arrow-left" />
                  Wróć do listy
                </button>
              )}

              {onSubmit !== false && !readonly && (
                <button className="btn btn-primary ml-1" type="submit" disabled={isSubmitting} onClick={handleSubmit}>
                  <i className={isSubmitting ? 'mdi mdi-spin mdi-cogs' : 'mdi mdi-content-save'} /> Zapisz
                </button>
              )}
            </Col>
            {inlineComponentAppendHeader(record)}
          </Row>
        )}
        <>
          <Row className={'form-tabs'}>
            <Col>
              <Nav tabs className="bg-dark-accent border-radius-top position-relative">
                {tabsToDisplay.map(el => (
                  <TabNavLink
                    key={`NavItem_${el.id}`}
                    tab={el}
                    fields={module.fields}
                    activeTab={activeTab}
                    onClick={() => {
                      toggle(el.id);
                    }}
                  />
                ))}
                {allowedCustomTabs.map((el, i) => (
                  <NavItem key={`NavItem_${i}`}>
                    <NavLink
                      style={{ cursor: 'pointer' }}
                      className={classnames({
                        active: activeTab === i,
                        'dark-accent-color': activeTab !== i,
                      })}
                      onClick={() => {
                        toggle(i);
                      }}
                    >
                      <span>{el.title}</span>
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
            </Col>
          </Row>
          <Row>
            <Col>
              {record && module && (
                <Card className="border-small border-top-0 border-radius-top-left-none">
                  <CardBody style={{ padding: 0 }}>
                    <TabContent activeTab={activeTab} className="text-muted">
                      {tabsToDisplay.map(el => (
                        <TabPane tabId={el.id} key={`TabPane${el.id}`}>
                          {activeTab === el.id && (
                            <CustomTab
                              tab={el}
                              values={record['@formValues']}
                              onUpdate={updateCustomFormValue}
                              fields={module.fields}
                              components={components}
                              readonly={readonly}
                              recordId={record?.['@id']}
                              forceReadonlyField={forceReadonlyField}
                            />
                          )}
                        </TabPane>
                      ))}
                      {allowedCustomTabs.map((el, index) => (
                        <TabPane tabId={index} key={`CTabPane${index}`}>
                          {activeTab === index && (
                            <el.component
                              {...(el.props ?? {})}
                              tab={el}
                              record={record}
                              module={module}
                              onUpdate={updateRecord}
                              fields={module.fields}
                              readonly={readonly}
                              recordId={record?.['@id']}
                            />
                          )}
                        </TabPane>
                      ))}
                    </TabContent>
                    {onSubmit !== false && !readonly && (
                      <div style={{ position: 'sticky', bottom: 0, zIndex: 460 }}>
                        <Paper className="col-md-12 p-3" variant="outlined">
                          <div className="d-flex space-between">
                            <div className="w-100 d-flex align-items-center">
                              <span>
                                {module.name}{' '}
                                {recordLabel && recordLabel(record, module.fields) ? `- ${recordLabel(record, module.fields)}` : ''}{' '}
                              </span>
                            </div>
                            <div className="w-100 text-end">
                              {submitActions(record)}
                              <button className="btn btn-primary ml-1" type="submit" disabled={isSubmitting} onClick={handleSubmit}>
                                <i className={isSubmitting ? 'mdi mdi-spin mdi-cogs' : 'mdi mdi-content-save'} /> Zapisz
                              </button>
                            </div>
                          </div>
                        </Paper>
                      </div>
                    )}
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
        </>
      </Container>
    );
  },
);
ApiForm.displayName = 'ApiForm';

export default ApiForm;
