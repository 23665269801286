import LabelerId from '../CustomFields/LabelerId';
import { Tooltip } from '@mui/material';
import classnames from 'classnames';
import _ from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FormFeedback, FormGroup, Input, InputGroup, Label, Row } from 'reactstrap';
import { useAppSelector } from 'store';

export type BaseInputProps = {
  className?: string;
  value: any;
  name: string;
  helpText?: string;
  label?: string | JSX.Element;
  type?: string;
  size?: { md: number };
  onChange: (value: any, name: string) => void;
  onClick?: () => void;
  inputProps?: any;
  beforeInputContent?: any;
  afterInputContent?: any;
  disabled?: boolean;
  disableGroupMargin?: boolean;
  pure?: boolean;
  errorKey?: string;
  fullHeight?: boolean;
  fullInputHeight?: boolean;
  verticalCenter?: boolean;
  hideArrows?: boolean;
  labelerId?: number;
};

const BaseInput: FC<BaseInputProps> = ({
  className = 'mb-3',
  value,
  name,
  helpText,
  label = '',
  type = 'text',
  size = { md: 12 },
  onChange,
  onClick = () => undefined,
  inputProps = {},
  beforeInputContent,
  afterInputContent,
  disabled = false,
  disableGroupMargin = false,
  pure,
  errorKey,
  fullHeight,
  fullInputHeight,
  verticalCenter,
  hideArrows = false,
  labelerId,
}) => {
  const [controlledState, setControlledState] = useState(value);
  const { t } = useTranslation();
  const { violations } = useAppSelector(state => ({
    violations: state.FormErrors.violations,
  }));

  useEffect(() => {
    if (value !== controlledState) {
      setControlledState(value);
    }
  }, [value]);

  const hasError = () => {
    return violations.hasOwnProperty(errorKey ?? name);
  };
  const getError = () => {
    if (hasError()) {
      return violations[errorKey ?? name].join('\n');
    }
    return '';
  };

  const handleChange = e => {
    const newVal = _.isEmpty(e.target.value) ? null : e.target.value;
    setControlledState(newVal);
    onChange(newVal, name);
  };

  if (pure) {
    return (
      <Input
        className="form-control"
        type={type}
        value={controlledState ?? ''}
        invalid={hasError()}
        onChange={handleChange}
        autoComplete={'off'}
        name={name}
        disabled={disabled}
        {...inputProps}
        onClick={onClick}
      />
    );
  }

  return (
    <Col {...size} className={className} style={fullHeight ? { height: '100%' } : {}}>
      <Row className={classnames('', { 'h-100': fullHeight })}>
        <FormGroup className={classnames({ 'disable-group-margin': disableGroupMargin })}>
          {label && label !== '' && (
            <Label style={{ width: '100%' }}>
              <>
                {typeof labelerId !== 'undefined' && <LabelerId labelerId={labelerId} />}
                {typeof label === 'string' ? t(label) : label}
                {helpText && (
                  <>
                    {' '}
                    <Tooltip title={helpText} placement={'right'} arrow={true}>
                      <i className="mdi mdi-information" />
                    </Tooltip>
                  </>
                )}
              </>
            </Label>
          )}
          <InputGroup
            style={{
              ...(verticalCenter
                ? {
                    top: '50%',
                    transform: 'translateY(-50%)',
                  }
                : {}),
              ...(fullInputHeight ? { height: 'calc(100% - 34px)' } : {}),
            }}
          >
            {beforeInputContent}
            <Input
              className={classnames('form-control', { 'hide-arrows': hideArrows })}
              type={type}
              value={controlledState ?? ''}
              invalid={hasError()}
              onChange={handleChange}
              autoComplete={'off'}
              name={name}
              disabled={disabled}
              {...inputProps}
              onClick={onClick}
            />
            {afterInputContent}
            {hasError() && <FormFeedback>{getError()}</FormFeedback>}
          </InputGroup>
        </FormGroup>
      </Row>
    </Col>
  );
};

export default BaseInput;
