import { usePropertyValues } from '../../Core/Hooks/usePropertyValues';
import ListPicker from 'components/Form/ListPicker';
import Loader from 'components/Theme/Common/Loader';

const ReservationPicker = ({ field, value, onChange, readonly, errorKey }) => {
  const { data: reservations, isLoading } = usePropertyValues('ecommerce-client-order', 'reservation');

  if (isLoading) {
    return <Loader />;
  }

  return (
    <ListPicker
      name={field.id}
      label={field.name}
      tabIndex={field._tabindex}
      value={(value || field.defaultValue) ?? 0}
      options={reservations}
      optionLabel="label"
      optionTrackBy="value"
      disabled={readonly}
      disableGroupMargin={true}
      labelBadge={field.position}
      errorKey={errorKey}
      onChange={val => onChange(val, field.id)}
    />
  );
};
// StatusPicker.provideAllValues = true;

export default ReservationPicker;
