import { ProductGroupType } from './Types/ProductGroup';
import CTMModule, { CTMListColumn } from 'Modules/Core/Types/CTMModule';
import View from 'Modules/Manufacture/Components/ProductGroup/View';
import ChildGrid from 'pages/Manufacture/ProductGroup/ChildGrid';

const columns: CTMListColumn<ProductGroupType>[] = [
  {
    id: 'name',
    filterable: true,
    sortable: true,
    Header: 'Nazwa',
    accessor: row => (
      <div>
        {(row.childrenCount ?? 0) > 0 && <i style={{ color: `#484f56` }} className={'mdi font-size-16 mdi-folder'} />}
        {row.name}
      </div>
    ),
  },
];

const module: CTMModule<ProductGroupType> = {
  id: 'df5fcb13-6caa-40e3-80c6-66b266118a7a',
  dataClass: 'CTM\\Manufacture\\Entity\\ProductGroup',
  urlPrefix: 'manufacture-product-groups',
  name: 'Kategorie produktów',
  role: 'MANUFACTURE_PRODUCT',
  api: {
    item: {
      get: ({ id }) => `/manufacture/product-groups/${id}`,
      put: ({ id }) => `/manufacture/product-groups/${id}`,
      delete: ({ id }) => `/manufacture/product-groups/${id}`,
    },
    collection: {
      get: `/manufacture/product-groups`,
      post: `/manufacture/product-groups`,
    },
  },
  recordLabel: ({ name }) => name,
  form: {},
  list: {
    inlineComponentOverride: View,
    columns: columns,
    defaultFilters: [{ id: 'exists[parent]', value: false }],
    defaultOrderBy: [{ id: 'position', desc: false }],
    renderRowSubComponent: ({ row }, modalListActions) => <ChildGrid parent={row.original.id} modalListActions={modalListActions} />,
    canRenderSubRow: ({ column: { id }, row: { original } }) => {
      if (id === 'actions' || id === 'actionsStickyRight') {
        return false;
      }
      return original.childrenCount > 0;
    },
  },
};

export default module;
