import { LabelerIdColumn } from '../Manufacture/Product';
import { Button } from '@mui/material';
import CTMModule, { CTMListColumn, CTMRecord } from 'Modules/Core/Types/CTMModule';
import classnames from 'classnames';
import { SelectColumnFilter } from 'components/DataGrid/Filter';

export type PlaceQuantity = CTMRecord & {
  id?: string;
};

const buttonStyle = { borderRadius: '50%', padding: '2px', minWidth: '30px', color: '#fff' };

const columns: CTMListColumn<PlaceQuantity>[] = [
  {
    id: 'product.labelerId',
    filterable: true,
    sortable: true,
    Header: 'ID',
    Cell: ({ row }) => <LabelerIdColumn row={{ ...row, original: row.original.product }} />,
    accessor: () => {
      return null;
    },
    width: 100,
  },
  {
    id: 'product.name',
    filterable: true,
    sortable: true,
    autofocus: true,
    Header: 'Nazwa',
    accessor: 'product.name',
  },
  {
    id: 'product.symbol',
    filterable: true,
    sortable: true,
    Header: 'Symbol',
    accessor: 'product.symbol',
  },
  {
    id: 'product.ean',
    filterable: true,
    sortable: true,
    Header: 'EAN',
    accessor: 'product.ean',
  },
  {
    id: 'product.species.id',
    Header: 'Gatunek',
    accessor: row => <>{row.product?.species?.name}</>,
    filterable: false,
    sortable: false,
  },
  {
    id: 'quantity',
    Header: 'Ilość',
    accessor: ({ quantity }) => (
      <Button variant={'contained'} color={'success'} style={buttonStyle}>
        {quantity}
      </Button>
    ),
    sortable: false,
  },
  {
    id: 'product.price',
    Header: 'Cena kat.',
    accessor: ({ product }) => {
      return product.price ?? 'd/u';
    },
    sortable: true,
  },
  {
    id: 'product.lastSupplyPrice',
    Header: 'Cena z o.d.',
    accessor: 'product.lastSupplyPrice',
    sortable: true,
  },
  {
    id: 'product.fifoPrice',
    Header: 'Cena FIFO',
    accessor: 'product.fifoPrice',
    sortable: true,
  },
  {
    id: 'product.group.id',
    filterable: false,
    sortable: false,
    Header: 'Grupa',
    accessor: 'product.group.name',
    minWidth: 160,
  },
  {
    id: 'product.type.id',
    filterable: false,
    sortable: false,
    Header: 'Typ',
    accessor: row => <>{row.product?.type?.name}</>,
    minWidth: 160,
  },
  {
    id: 'product.active',
    Header: 'Aktywny',
    accessor: ({ product }) => {
      return (
        <div className="font-size-20 text-center" style={{ margin: '0 auto' }}>
          <i
            className={classnames('mdi', {
              'mdi-check-circle text-success': product.active === true,
              'mdi-minus-circle text-danger': product.active !== true,
            })}
          />
        </div>
      );
    },
    filterable: true,
    Filter: SelectColumnFilter,
    filterOptions: [
      { value: null, label: 'Wszystkie' },
      { value: true, label: 'Aktywny' },
      { value: '0', label: 'Nieaktywny' },
    ],
  },
];
const module: CTMModule<PlaceQuantity> = {
  id: 'd66cc193-c57f-4328-ba92-fb51b412832f',
  dataClass: 'CTM\\Warehouse\\Entity\\View\\ProductQuantityInsidePlace',
  urlPrefix: 'warehouse-product-quantity-inside-place',
  name: 'Towary na miejscach magaznowych',
  role: 'WAREHOUSE_WAREHOUSE',
  api: {
    item: {
      get: () => '',
      put: () => '',
      delete: () => '',
    },
    collection: {
      get: `/warehouse/product-quantity-inside-places`,
      post: '',
    },
  },
  list: {
    columns: columns,
    defaultFilters: [],
    defaultOrderBy: [{ id: 'quantity', desc: true }],
    storeFilters: false,
  },
};

export default module;
