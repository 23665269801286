import { CTMModuleContractor } from 'Modules/Contractor/Contractor';
import BaseInput from 'components/Form/BaseInput';
import Loader from 'components/Theme/Common/Loader';
import { useModuleContext } from 'context/ModulesContext';
import { get } from 'helpers/Axios';
import { FC, useState } from 'react';
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export const TinLoader: FC<{ onLoaded: (data: any) => any }> = ({ onLoaded = data => {} }): JSX.Element => {
  const [modal, setModal] = useState(false);
  const [tin, setTin] = useState<string | null>(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const ContractorModule = useModuleContext<CTMModuleContractor, true>('contractor-contractor');

  const toggle = () => setModal(!modal);

  const load = () => {
    setLoading(true);
    setError(false);
    get(ContractorModule.configuration.api.item.getTin(tin ?? ''))
      .then(contractor => {
        if (contractor.name === '') {
          setError(true);
          return;
        }

        onLoaded(contractor);
        toggle();
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <span>
      <button className="btn btn-danger ml-1" onClick={toggle}>
        <i className="mdi mdi-database" /> Pobierz z GUS
      </button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Pobieranie danych kontrahenta z bazy GUS</ModalHeader>
        <ModalBody>
          {loading && (
            <div className="mb-4">
              <Loader />
            </div>
          )}
          {error && (
            <Alert color="danger" role="alert">
              Nie znaleziono numeru NIP w bazie GUS.
            </Alert>
          )}
          <BaseInput
            size={{ md: 12 }}
            className={''}
            value={tin}
            type={'text'}
            name="tin"
            onChange={value => setTin(value)}
            inputProps={{
              placeholder: 'Wpisz numer NIP',
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={load}>
            Załaduj
          </Button>{' '}
          <Button color="secondary" onClick={toggle}>
            Anuluj
          </Button>
        </ModalFooter>
      </Modal>
    </span>
  );
};
