import AcceptClientRegistration from './Components/List/AcceptClientRegistration';
import CTMModule, { CTMListColumn, CTMRecord } from 'Modules/Core/Types/CTMModule';
import classnames from 'classnames';

export type ClientRegistrationRequest = CTMRecord & {
  id?: string;
};

const columns: CTMListColumn<ClientRegistrationRequest>[] = [
  {
    id: 'firstName',
    filterable: true,
    sortable: true,
    Header: 'Imię',
    accessor: 'firstName',
  },
  {
    id: 'lastName',
    Header: 'Nazwisko',
    accessor: 'lastName',
    filterable: true,
    sortable: true,
  },
  {
    id: 'email',
    Header: 'Email',
    accessor: 'email',
    filterable: true,
    sortable: true,
  },
  {
    id: 'phone',
    Header: 'Telefon',
    accessor: 'phone',
    filterable: true,
    sortable: true,
  },
  {
    id: 'storeClientRegistrationRequest.isCompany',
    Header: 'B2B',
    accessor: ({ storeClientRegistrationRequest: { isCompany } }) => {
      return (
        <div className="font-size-20 text-center" style={{ margin: '0 auto' }}>
          <i
            className={classnames('mdi', {
              'mdi-check-circle text-success': isCompany === true,
              'mdi-minus-circle text-danger': isCompany !== true,
            })}
          />
        </div>
      );
    },
    filterable: false,
    sortable: false,
  },
  {
    id: 'storeClientRegistrationRequest.tin.number',
    Header: 'NIP',
    accessor: ({
      storeClientRegistrationRequest: {
        tin: { prefix, number },
      },
    }) => `${prefix ?? ''} ${number ?? ''}`,
    filterable: false,
    sortable: false,
  },
];

interface CustomClientOrderItemPaths {
  get: ({ id }: { id: string | number }) => string;
  accept: ({ id }: { id: string | number }) => string;
}

const module: CTMModule<ClientRegistrationRequest, CustomClientOrderItemPaths> = {
  id: 'e4ce92b5-09f6-4d67-9d9d-20f04575233f',
  dataClass: 'CTM\\Ecommerce\\View\\ClientRegistrationRequest',
  urlPrefix: 'ecommerce-client-registration-request-views',
  name: 'Formularze rejestracyjne',
  role: 'ECOMMERCE_ORDER',
  api: {
    item: {
      get: ({ id }) => `/ecommerce/client-registration-request-views/${id}`,
      put: ({ id }) => `/ecommerce/client-registration-request-views/${id}`,
      delete: ({ id }) => `/ecommerce/client-registration-request-views/${id}`,
      accept: ({ id }) => `/ecommerce/client-registration-request-views/${id}/accept`,
    },
    collection: {
      get: `/ecommerce/client-registration-request-views`,
      post: `/ecommerce/client-registration-request-views`,
    },
  },
  recordLabel: (record, allFields) => record.email,
  form: {
    disableCreateNewRecord: true,
    disableEditRecord: true,
    disableShowRecord: true,
  },
  list: {
    columns: columns,
    storeFilters: true,
    inlineComponentAppendActions: (params, listRef) => <AcceptClientRegistration id={params.id} />,
  },
};

export default module;
