import { LeadModule } from 'Modules/CTMModules';
import FileUpload from 'components/Form/FileUpload';
import BaseForm from 'components/Theme/Common/BaseForm';
import Loader from 'components/Theme/Common/Loader';
import { Container } from 'components/Theme/Grid';
import { post } from 'helpers/Axios';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { addSingleToast } from 'store/Toast/actions';

const ImportLead = () => {
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();

  const importFile = fileId => {
    setLoading(true);

    post(LeadModule.configuration.api.collection.import, { fileId: fileId })
      .then(() => {
        setLoading(false);
        dispatch(
          addSingleToast({
            title: 'Plik został poprawnie załadowany. Import w trakcie.',
            config: { appearance: 'success' },
          }),
        );
      })
      .catch(error => {
        setLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  const DetailsTab = () => (
    <>
      <Container justifyContent="space-between">
        <FileUpload name={'file'} value={null} label={'Plik importu'} returnObject={true} onChange={file => importFile(file.id)} />
      </Container>
    </>
  );

  if (loading) {
    return (
      <div className="container-fluid">
        <Loader />
      </div>
    );
  }

  return (
    <div className="container-fluid">
      <BaseForm
        title={`Import leadów sprzedażowych`}
        tabs={[{ title: 'Ogólne', content: <DetailsTab /> }]}
        readonly={true}
        contextActions={
          <>
            <button
              className="btn btn-primary ml-1"
              onClick={() => {
                history.goBack();
              }}
            >
              <i className="mdi mdi-arrow-left" />
              Wróć do listy
            </button>
          </>
        }
      />
    </div>
  );
};

export default ImportLead;
