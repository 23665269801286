import { Button } from '@mui/material';
import { usePropertyValues } from 'Modules/Core/Hooks/usePropertyValues';
import ListPicker from 'components/Form/ListPicker';
import Loader from 'components/Theme/Common/Loader';

const StatusPicker = ({ field, value, onChange, readonly, errorKey }) => {
  const { data: statuses, isLoading } = usePropertyValues('ecommerce-client-order', 'status');

  if (isLoading) {
    return <Loader />;
  }

  return (
    <ListPicker
      name={field.id}
      label={field.name}
      tabIndex={field._tabindex}
      value={(value !== null ? value : field.defaultValue) ?? 500}
      options={statuses}
      optionLabel={item => (
        <Button
          variant={'contained'}
          style={{
            width: '100%',
            padding: '3px',
            color: '#fff',
            margin: '0 2px',
            backgroundColor: item?.color,
          }}
        >
          {item?.label}
        </Button>
      )}
      optionTrackBy="value"
      disabled={readonly}
      disableGroupMargin={true}
      labelBadge={field.position}
      errorKey={errorKey}
      onChange={val => onChange(val, field.id)}
    />
  );
};

export default StatusPicker;
