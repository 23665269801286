import CTMModule, { CTMListColumn, CTMRecord } from 'Modules/Core/Types/CTMModule';

export type Tariff = CTMRecord & {
  id?: string;
  name?: string;
  price?: number;
};

const columns: CTMListColumn<Tariff>[] = [
  {
    id: 'name',
    Header: 'Nazwa',
    accessor: 'name',
    filterable: true,
    sortable: true,
  },
  {
    id: 'profit',
    Header: 'Marża[%]',
    accessor: ({ profit }) => profit + '%',
    filterable: true,
    sortable: true,
  },
];

const module: CTMModule<Tariff> = {
  id: '94321170-a3ff-4f9f-8186-890efb23b4e2',
  dataClass: 'CTM\\Manufacture\\Entity\\Price\\Tariff',
  urlPrefix: 'manufacture-price-tariffs',
  name: 'Cenniki',
  role: 'MANUFACTURE_PRODUCT',
  api: {
    item: {
      get: ({ id }) => `/manufacture/price/tariffs/${id}`,
      put: ({ id }) => `/manufacture/price/tariffs/${id}`,
      delete: ({ id }) => `/manufacture/price/tariffs/${id}`,
    },
    collection: {
      get: `/manufacture/price/tariffs`,
      post: `/manufacture/price/tariffs`,
    },
  },
  recordLabel: (record, allFields) => record.name,
  form: {},
  list: {
    columns: columns,
    defaultFilters: [],
    defaultOrderBy: [{ id: 'name', desc: false }],
  },
};

export default module;
