import ProductTechnologyFilesTree from './ProductTechnologyFilesTree';
import { Field } from '@CustomFields/Field';
import { FileDownload, Folder, QuestionMark, Warning } from '@mui/icons-material';
import { Alert, ListItemAvatar } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { ProductModule } from 'Modules/CTMModules';
import { Product, TechnologyFile } from 'Modules/Manufacture/Types/Product';
import { Technology } from 'Modules/Manufacture/Types/Technology';
import TextInput from 'components/Form/MUI/TextInput';
import MultipleFileUpload from 'components/Form/MultipleFileUpload';
import Loader from 'components/Theme/Common/Loader';
import { Col, Container } from 'components/Theme/Grid';
import { get } from 'helpers/Axios';
import { FC, memo, useCallback, useEffect, useState } from 'react';
import { useAppSelector } from 'store';
import { v4 as uuidv4 } from 'uuid';

interface ListItemFileControlledProps {
  technologyFileIndex: number;
  fileName?: null | string;
  fileURL?: null | string;
  fileDescription?: null | string;
  onChange: any;
  onRemove: () => void;
}

const ListItemFileControlled = (props: ListItemFileControlledProps) => {
  const handleChange = useCallback((value: undefined | string | null, name: undefined | string) => {
    props.onChange(name, value);
  }, []);

  return (
    <div className={'file-container'}>
      <div className={'remove-file-dot'} onClick={() => props.onRemove()}>
        <i className="cf-field-btn mdi mdi-delete" />
      </div>
      <ListItem dense={true}>
        <ListItemAvatar sx={{ minWidth: 35 }}>
          <FileDownload style={{ cursor: 'pointer' }} onClick={() => window.open(props.fileURL ?? '', '_blank')} />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Container spacing={0}>
              <Col xs={12}>
                <TextInput label={'Tytuł'} value={props.fileName} textarea={true} onChange={handleChange} name={'name'} />
              </Col>
            </Container>
          }
        />
      </ListItem>
      <ListItem dense={true}>
        <Container spacing={0}>
          <Col xs={12}>
            <TextInput label={'Opis'} value={props.fileDescription} textarea={true} onChange={handleChange} name={'description'} />
          </Col>
        </Container>
      </ListItem>
    </div>
  );
};

const ListItemFileControlledMemo = memo(ListItemFileControlled);

interface ListItemFileProps {
  technologyFileIndex: number;
  technologyFile: TechnologyFile & { _key: string };
  onChange: (key, field, value) => void;
  onRemove: (key: string) => void;
}

const ListItemFile = (props: ListItemFileProps) => {
  const handleChange = useCallback(
    (fieldName, val) => {
      props.onChange(props.technologyFile._key, fieldName, val);
    },
    [props.technologyFile._key],
  );

  const handleRemove = useCallback(() => {
    props.onRemove(props.technologyFile._key);
  }, [props.technologyFile._key]);

  return (
    <ListItemFileControlledMemo
      fileDescription={props.technologyFile.description}
      fileName={props.technologyFile.name ?? props.technologyFile.file.originalName}
      fileURL={props.technologyFile.file.contentUrl}
      technologyFileIndex={props.technologyFileIndex}
      onRemove={handleRemove}
      onChange={handleChange}
    />
  );
};

const ListItemFileReadonly = (props: ListItemFileProps) => {
  return (
    <>
      <ListItem alignItems="flex-start">
        <ListItemAvatar sx={{ minWidth: 35 }}>
          <FileDownload style={{ cursor: 'pointer' }} onClick={() => window.open(props.technologyFile.file.contentUrl, '_blank')} />
        </ListItemAvatar>
        <ListItemText
          primary={
            props.technologyFile.name && props.technologyFile.name !== ''
              ? props.technologyFile.name
              : props.technologyFile.file.originalName
          }
          secondary={<>{props.technologyFile.description}</>}
        />
      </ListItem>
      <Divider variant="inset" component="li" />
    </>
  );
};

interface AlignItemsListProps {
  readonly: boolean;
  fileTypeIRI: string;
  files: (TechnologyFile & { _key: string })[];
  onChange: any;
  onRemove: (technologyFileIndex: any) => void;
  onAdd: (newFiles: (TechnologyFile & { _key: string })[]) => void;
}

const AlignItemsList = (props: AlignItemsListProps) => {
  const handleInputChange = useCallback(uploadedFiles => {
    props.onAdd([
      ...uploadedFiles.map(file => ({
        name: file.originalName,
        description: null,
        file: file,
        technologyFileType: props.fileTypeIRI,
        _key: uuidv4(),
      })),
    ]);
  }, []);

  const ListItemComponent = props.readonly ? ListItemFileReadonly : ListItemFile;

  return (
    <List
      sx={{
        width: '100%',
        maxWidth: 360,
        maxHeight: 'calc(100vh - 450px)',
        overflow: 'auto',
        bgcolor: 'background.paper',
        position: 'relative',
        padding: '12px 12px !important',
      }}
    >
      {props.files.map((el, i) => (
        <ListItemComponent
          key={`tf_${i}`}
          technologyFileIndex={i}
          technologyFile={el}
          onChange={props.onChange}
          onRemove={props.onRemove}
        />
      ))}
      {!props.readonly && (
        <ListItem
          alignItems="flex-start"
          sx={{
            position: 'sticky',
            bottom: 0,
            paddingBottom: '10px !important',
            background: '#fff',
            zIndex: 20,
          }}
        >
          <MultipleFileUpload
            value={[]}
            size={{ md: 12 }}
            disableGroupMargin={true}
            className="col-sm-12"
            onChange={handleInputChange}
            dropzone={(getRootProps, getInputProps) => (
              <div className="dropzone dropzone-image" style={{ minHeight: 'initial' }}>
                <div className="dz-message needsclick" {...getRootProps()} style={{ padding: 6 }}>
                  <input {...getInputProps()} />
                  <Container justifyContent={'center'} alignItems={'center'}>
                    <Col>
                      <i className="display-4 text-muted bx bxs-cloud-upload" />
                    </Col>
                    <Col>
                      <h4>Upuść pliki tutaj</h4>
                    </Col>
                  </Container>
                </div>
              </div>
            )}
            name={'uploadedFiles'}
          />
        </ListItem>
      )}
    </List>
  );
};

interface FileTypeBucketProps {
  recordId: null | string;
  readonly: boolean;
  fileTypeIRI: string;
  fileTypeId?: null | string;
  fileTypeName?: null | string;
  fileTypeDescription?: null | string;
  files: (TechnologyFile & { _key: string })[];
  onChange: (key, field, value) => void;

  onRemove: (technologyFileIndex: any) => void;
  onAdd: (newFiles: (TechnologyFile & { _key: string })[]) => void;
}

const FileTypeBucket = (props: FileTypeBucketProps) => {
  const token = useAppSelector(root => root.Login.token);

  if (!token) {
    return <></>;
  }

  const downloadURL = `${process.env.REACT_APP_API_BASE_URL}${ProductModule.configuration.api.item.downloadTechnologyFiles({
    id: props.recordId ?? '',
    technologyFileTypeId: props.fileTypeId ?? '',
  })}?token=${encodeURIComponent(token)}`;

  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: '#2a3041' }} aria-label="recipe">
            <Folder />
          </Avatar>
        }
        action={
          props.recordId && props.files.length === 0 ? null : (
            <Avatar sx={{ bgcolor: '#4c5263', cursor: 'pointer' }} aria-label="recipe">
              <FileDownload onClick={() => window.open(downloadURL, '_blank')} />
            </Avatar>
          )
        }
        title={props.fileTypeName}
      />
      <CardContent style={{ padding: 0 }}>
        <Typography variant="body2" color="text.secondary">
          {props.fileTypeDescription && (
            <Alert icon={<QuestionMark />} color={'info'}>
              {props.fileTypeDescription}
            </Alert>
          )}
          <AlignItemsList
            files={props.files}
            onChange={props.onChange}
            onRemove={props.onRemove}
            fileTypeIRI={props.fileTypeIRI}
            readonly={props.readonly}
            onAdd={props.onAdd}
          />
        </Typography>
      </CardContent>
    </Card>
  );
};

interface ProductTechnologiesProps {
  record: Product;
  onUpdate: any;
  setRecord: any;
  readonly: boolean;
  fields: Field[];
}

const ProductTechnologies: FC<ProductTechnologiesProps> = ({ record, onUpdate, readonly }) => {
  const [files, setFiles] = useState<(TechnologyFile & { _key: string })[]>(
    record.technologyFiles?.map(el => ({
      ...el,
      _key: el?._key ?? uuidv4(),
    })) ?? [],
  );
  const [technology, setTechnology] = useState<null | Technology>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      const recordTechnologyIRI = record?.technology?.['@id'] ?? record?.technology;
      const currentTechnologyIRI = technology?.['@id'] ?? technology;
      if (recordTechnologyIRI !== currentTechnologyIRI) {
        if (recordTechnologyIRI) {
          const res = await get(recordTechnologyIRI, { params: { disableCustomFields: true } });
          setTechnology(res);
          setLoading(false);
        } else {
          setLoading(false);
          setTechnology(null);
        }
      } else {
        setLoading(false);
      }

      if (JSON.stringify(record.technologyFiles) !== JSON.stringify(files)) {
        setFiles(
          record.technologyFiles?.map(el => ({
            ...el,
            _key: el?._key ?? uuidv4(),
          })) ?? [],
        );
      }
    })();
  }, [JSON.stringify(record?.technologyFiles)]);

  useEffect(() => {
    if (JSON.stringify(record.technologyFiles) !== JSON.stringify(files)) {
      onUpdate(prevRecord => ({
        ...prevRecord,
        technologyFiles: [...files],
      }));
    }
  }, [JSON.stringify(files)]);

  const handleChange = useCallback((key, field, value) => {
    setFiles(prevFiles =>
      prevFiles.map(el => {
        if (el._key === key) {
          el[field] = value;
        }

        return el;
      }),
    );
  }, []);
  const handleRemove = useCallback(key => {
    setFiles(prevFiles => prevFiles.filter(el => el._key !== key));
  }, []);
  const handleAdd = useCallback(newCollection => {
    setFiles(prevFiles => [...prevFiles, ...newCollection]);
  }, []);

  if (loading) {
    return <Loader />;
  }

  const filterFiles = el => elFile => (elFile.technologyFileType?.['@id'] ?? elFile.technologyFileType) === el['@id'];

  return (
    <>
      {(technology?.fileTypes ?? []).length === 0 && (
        <Alert icon={<Warning />} color={'warning'}>
          Skonfiguruj dostępne typy plików w ustawieniach technologii
        </Alert>
      )}
      <div className="technology-file-container">
        {technology?.fileTypes
          ?.filter(el => !readonly || files.filter(filterFiles(el)).length > 0)
          .map(el => (
            <div className="technology-file-wrapper" key={el.id}>
              <FileTypeBucket
                readonly={readonly}
                recordId={record.id}
                fileTypeId={el.id}
                fileTypeIRI={el['@id']}
                fileTypeName={el.name}
                fileTypeDescription={el.description}
                files={files.filter(filterFiles(el))}
                onChange={handleChange}
                onRemove={handleRemove}
                onAdd={handleAdd}
              />
            </div>
          ))}
      </div>
      <ProductTechnologyFilesTree product={record} onUpdate={onUpdate} />
    </>
  );
};

export default ProductTechnologies;
