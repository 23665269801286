import ModuleListPicker from '../Module/ModuleListPicker';
import { Tooltip } from '@mui/material';
import { ApiItem, get } from 'helpers/Axios';
import { FC, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button, ButtonGroup } from 'reactstrap';
import { addSingleToast } from 'store/Toast/actions';

type InputAdditionalActionProps = {
  value: string | number | null;
  onChange: (value: string | number | null) => void;
  readonly: boolean;
  fieldId: string | null;
};

const InputAdditionalAction: FC<InputAdditionalActionProps> = ({ value, onChange, readonly, fieldId = null }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const moduleName = useMemo<string>(() => location.pathname.split('/')[2] ?? null, [location.pathname]);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(value?.toString() || '');
    dispatch(
      addSingleToast({
        title: `Skopiowano wartość "${value}" do schowka .`,
        config: { appearance: 'success' },
      }),
    );
  };

  const pasteFromClipboard = () => {
    navigator.clipboard.readText().then(value => {
      onChange(value);
      dispatch(
        addSingleToast({
          title: `Wklejono wartość "${value}" ze schowka.`,
          config: { appearance: 'success' },
        }),
      );
    });
  };

  const onSelect = (object: ApiItem | null) => {
    if (!object) return;

    get(object['@id']).then(response => {
      onChange(response['@formValues'][fieldId ?? '']);
      dispatch(
        addSingleToast({
          title: `Skopiowano wartość z wybranego obiektu.`,
          config: { appearance: 'success' },
        }),
      );
    });
  };

  return (
    <div className="input--more-actions">
      <i className="mdi mdi-dots-vertical btn-expander" />
      <ButtonGroup className={'input--more-buttons'}>
        {!readonly && (
          <>
            {moduleName && fieldId && (
              <ModuleListPicker<ApiItem & { [key: string]: any }>
                name={'_'}
                disabled={false}
                moduleName={moduleName}
                onChange={onSelect}
                disableClear={true}
                defaultFilters={null}
                overrideUrl={undefined}
                moduleFormComponent={undefined}
                moduleFormComponentProps={undefined}
                moduleFormWrapperProps={undefined}
              >
                <Button className="btn-sm" style={{ height: '100%', background: 'transparent', borderColor: '#dedede' }}>
                  <Tooltip title={'Skopiuj pole z innego rekrodu'}>
                    <i className="mdi mdi-eye text-info" style={{ padding: '0 4px' }} />
                  </Tooltip>
                </Button>
              </ModuleListPicker>
            )}
            <Button onClick={pasteFromClipboard} className="btn-sm" style={{ background: 'transparent', borderColor: '#dedede' }}>
              <Tooltip title={'Wklej ze schowka'}>
                <i className="mdi mdi-content-paste text-danger" style={{ padding: '0 4px' }} />
              </Tooltip>
            </Button>
          </>
        )}

        <Button onClick={copyToClipboard} className="btn-sm" style={{ background: 'transparent', borderColor: '#dedede' }}>
          <Tooltip title={'Kopiuj do schowka'}>
            <i className="mdi mdi-content-copy text-success" style={{ padding: '0 4px' }} />
          </Tooltip>
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default InputAdditionalAction;
