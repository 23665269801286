import logoLightSvg from 'assets/images/logo-light.svg';
import logo from 'assets/images/logo.svg';
import SelectSingleAPI from 'components/Form/SelectSingleAPI';
import ChatDropdownIcon from 'components/Theme/Common/ChatDropdownIcon';
import ProfileMenu from 'components/Theme/CommonForBoth/TopbarDropdown/ProfileMenu';
import { useModulesContext } from 'context/ModulesContext';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { changeSidebarType, toggleLeftmenu } from 'store/actions';

const Header = props => {
  const lastShiftPress = useRef(0);
  const shiftPressCount = useRef(0);
  const wrapperRef = useRef();
  const [search, setsearch] = useState(false);
  const modules = useModulesContext();
  const history = useHistory();

  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === 'Shift') {
        console.log(lastShiftPress.current, shiftPressCount.current);
        const now = Date.now();

        if (now - lastShiftPress.current < 500) {
          // Check if the second press is within 300ms
          shiftPressCount.current = shiftPressCount.current + 1;
        } else {
          shiftPressCount.current = 1;
        }

        lastShiftPress.current = now;

        if (shiftPressCount.current >= 2) {
          wrapperRef.current.querySelector('.select2-selection__input').focus();
          shiftPressCount.current = 0;
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [lastShiftPress, shiftPressCount]);

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle('sidebar-enable');
    } else {
      body.classList.toggle('vertical-collpsed');
      body.classList.toggle('sidebar-enable');
    }
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box d-lg-none d-md-block">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="22" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoLightSvg} alt="" height="22" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={() => {
                tToggle();
              }}
              className="btn btn-sm px-3 font-size-16 header-item "
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>

            <form className="app-search d-none d-lg-block">
              <div className="position-relative" ref={wrapperRef}>
                <SelectSingleAPI
                  label={false}
                  clearAfterSelect={true}
                  className={''}
                  style={{ width: '100%', minWidth: 320 }}
                  disableGroupMargin={true}
                  name={'searchBar'}
                  buildQuery={v => ({
                    query: v,
                  })}
                  value={null}
                  onChange={(iri, name, val) => {
                    const module = Object.values(modules.modules).find(el => el.configuration.id === val.data.module.id);
                    if (module) {
                      history.push(module.showUrl(val.data.record.id));
                    }
                  }}
                  endpoint={`index/test1`}
                  optionLabel={option => (
                    <div className="search-result">
                      <div className="search-result-record">{option.data.record.name}</div>
                      <div className="search-result-module">{option.data.module.name}</div>
                    </div>
                  )}
                  isClearable={false}
                />
                {/*<input type="text" className="form-control" placeholder={props.t('Search') + '...'} />*/}
              </div>
            </form>
          </div>
          <div className="d-flex">
            <div className="dropdown d-inline-block d-lg-none ms-2">
              <button
                onClick={() => {
                  setsearch(!search);
                }}
                type="button"
                className="btn header-item noti-icon "
                id="page-header-search-dropdown"
              >
                <i className="mdi mdi-magnify" />
              </button>
              <div
                className={
                  search
                    ? 'dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show'
                    : 'dropdown-menu dropdown-menu-lg dropdown-menu-end p-0'
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input type="text" className="form-control" placeholder="Search ..." aria-label="Recipient's username" />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div className="dropdown d-none d-lg-inline-block ms-1">
              <Link className="btn header-item noti-icon " to="/notes/create">
                <i className="bx bxs-file-plus"></i>
              </Link>
            </div>

            <div className="dropdown d-none d-lg-inline-block ms-1">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen();
                }}
                className="btn header-item noti-icon "
                data-toggle="fullscreen"
              >
                <i className="bx bx-fullscreen" />
              </button>
            </div>

            <ChatDropdownIcon />

            <ProfileMenu />

            <div className="dropdown d-inline-block">
              <Link to="/logout">
                <button type="button" className="btn header-item noti-icon right-bar-toggle ">
                  <i className="bx bx-power-off text-danger " />
                </button>
              </Link>
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
};

const mapStatetoProps = state => {
  const { layoutType, leftMenu, leftSideBarType } = state.Layout;
  return { layoutType, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header));
