import ListPicker from 'components/Form/ListPicker';

const TextareaTypeConfiguration = ({ configuration, onConfigurationUpdated }) => {
  const updateConfiguration = (value, field) => {
    onConfigurationUpdated({ ...configuration, [field]: value });
  };

  return (
    <ListPicker
      name={'editor'}
      label={'Typ prezentacji'}
      value={configuration?.editor || null}
      options={[
        { val: null, label: 'textarea' },
        { val: 'CKEDITOR', label: 'CKE Editor' },
      ]}
      optionLabel="label"
      optionTrackBy="val"
      onChange={updateConfiguration}
      disableGroupMargin={true}
    />
  );
};

export default TextareaTypeConfiguration;
