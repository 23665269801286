const Loader = ({ children = undefined }) => {
  return (
    <div className="text-center">
      <i className="mdi mdi-spin mdi-loading mdi-48px" />
      <br />
      <span>{children ?? <>Trwa ładowanie...</>}</span>
    </div>
  );
};

export default Loader;
