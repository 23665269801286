import user1 from '../../assets/images/users/avatar-1.jpg';
import Role from './Role';
import { Employee } from './Types/Employee';
import CTMModule, { CTMListColumn } from 'Modules/Core/Types/CTMModule';
import classnames from 'classnames';
import { SelectColumnFilter, SelectModuleRecordColumnFilter } from 'components/DataGrid/Filter';
import { createModuleColumnConfig, extractIRIFromRecord, pickValueFromRecordByPropertyPath } from 'helpers/ModuleUtils';
import PinField from 'pages/Employee/Employee/Components/PinField';
import PinPreview from 'pages/Employee/Employee/Components/PinPreview';

const columns: CTMListColumn<Employee>[] = [
  {
    id: 'firstName',
    filterable: true,
    sortable: true,
    Header: 'Imię',
    accessor: row => (
      <div>
        <img
          className="rounded-circle header-profile-user"
          style={{ width: 30, height: 30 }}
          src={row.image || user1}
          alt="Header Avatar"
        />
        <span className="d-none d-xl-inline-block ms-2 me-1">{row.firstName}</span>
      </div>
    ),
  },
  {
    id: 'lastName',
    Header: 'Nazwisko',
    accessor: 'lastName',
    filterable: true,
    sortable: true,
  },
  {
    id: 'email',
    Header: 'Email',
    accessor: 'email',
    filterable: true,
    sortable: true,
  },
  {
    id: 'phone',
    Header: 'Telefon',
    accessor: 'phone',
    filterable: true,
    sortable: true,
  },
  {
    id: 'pin',
    Header: 'PIN',
    accessor: ({ pin }) => <PinPreview pin={pin} />,
  },
  {
    id: 'role.id',
    Header: 'Rola',
    filterable: true,
    sortable: true,
    minWidth: 160,
    ...createModuleColumnConfig('role', Role),
  },
  {
    id: 'supervisor.id',
    Header: 'Bezpośredni przełożony',
    accessor: row => {
      if (!row.supervisor) {
        return null;
      }
      return `${row.supervisor.firstName} ${row.supervisor.lastName}`;
    },
    filterable: true,
    Filter: SelectModuleRecordColumnFilter,
    filterModuleName: 'employee-employee',
    minWidth: 160,
  },
  {
    id: 'active',
    Header: 'Aktywny',
    accessor: ({ active }) => {
      return (
        <div className="font-size-20 text-center" style={{ margin: '0 auto' }}>
          <i
            className={classnames('mdi', {
              'mdi-check-circle text-success': active === true,
              'mdi-minus-circle text-danger': active !== true,
            })}
          />
        </div>
      );
    },
    filterable: true,
    Filter: SelectColumnFilter,
    filterOptions: [
      { value: null, label: 'Wszystkie' },
      { value: true, label: 'Tak' },
      { value: '0', label: 'Nie' },
    ],
  },
];

export type EmployeeModule = CTMModule<
  Employee,
  {
    myPermissions: string;
    getMyConfiguration: string;
    putMyConfiguration: string;
    putResetPassword: ({ token }: { token: string }) => string;
    postRefreshToken: string;
    postLostPassword: string;
    postLostPin: string;
  }
>;

const module: EmployeeModule = {
  id: '223491f8-b6d7-4a4c-80bd-1825de81ad50',
  dataClass: 'CTM\\Employee\\Entity\\Employee',
  urlPrefix: 'employee-employee',
  name: 'Pracownicy',
  role: 'EMPLOYEE',
  api: {
    item: {
      get: ({ id }) => `/employee/employees/${id}`,
      put: ({ id }) => `/employee/employees/${id}`,
      delete: ({ id }) => `/employee/employees/${id}`,
      myPermissions: '/employee/my/permissions',
      getMyConfiguration: '/employee/my/configuration',
      putMyConfiguration: '/employee/my/configuration',
      postRefreshToken: '/refresh-token',
      putResetPassword: ({ token }) => `/employee/employee/reset-password/${token}`,
      postLostPassword: '/lost-password',
      postLostPin: '/lost-pin',
    },
    collection: {
      get: `/employee/employees`,
      post: `/employee/employees`,
    },
  },
  recordLabel: (record, allFields) => {
    const { firstName, lastName, email } = pickValueFromRecordByPropertyPath<Employee, Pick<Employee, 'firstName' | 'lastName' | 'email'>>(
      record,
      ['firstName', 'lastName', 'email'],
      allFields,
    );
    return firstName || lastName || email ? `${firstName ?? ''} ${lastName ?? ''} ${email ? `(${email})` : ''}` : null;
  },
  form: {
    prepareRecordToSave: record => {
      return {
        ...record,
        image: extractIRIFromRecord(record?.image),
        supervisor: extractIRIFromRecord(record?.supervisor),
        role: extractIRIFromRecord(record?.role),
      };
    },
    fieldComponents: {
      pin: PinField,
    },
  },
  list: {
    columns: columns,
    defaultFilters: [],
    defaultOrderBy: [{ id: 'id', desc: true }],
  },
};

export default module;
