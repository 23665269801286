import { ProductModule } from '../../CTMModules';
import { Button, Table, TableBody, TableCell, TableRow } from '@mui/material';
import ReservationModal from 'Modules/Warehouse/Components/Warehouse/ReservationModal';
import { memo, useEffect, useState } from 'react';

const buttonStyle = {
  borderRadius: '5px',
  padding: '2px 4px',
  minWidth: '30px',
  color: '#fff',
  margin: '0 2px',
};

const StoreValuesContent = memo(
  ({ id, quantityOrderedAvailable, quantityStock, quantityOrdered, quantityAvailable, quantityReserved, quantityOrderedReserved }) => {
    return (
      <Table>
        <TableBody>
          <TableRow padding={'none'}>
            <TableCell padding={'none'} style={{ border: 'none' }}>
              <Button variant={'contained'} color={'info'} style={buttonStyle}>
                {quantityStock ?? 0}
              </Button>
            </TableCell>
            <TableCell padding={'none'} style={{ border: 'none' }}>
              <ReservationModal productId={id} type={'RM'}>
                <Button variant={'contained'} color={'warning'} style={buttonStyle}>
                  {quantityReserved ?? 0}
                </Button>
              </ReservationModal>
            </TableCell>
            <TableCell padding={'none'} style={{ border: 'none' }}>
              <Button variant={'contained'} color={'success'} style={buttonStyle}>
                {quantityAvailable ?? 0}
              </Button>
            </TableCell>
            <TableCell padding={'none'} style={{ border: 'none' }}>
              <Button variant={'contained'} color={'primary'} style={buttonStyle}>
                {quantityOrdered ?? 0}
              </Button>
            </TableCell>
            <TableCell padding={'none'} style={{ border: 'none' }}>
              <ReservationModal productId={id} type={'RZ'}>
                <Button variant={'contained'} color={'error'} style={buttonStyle}>
                  {quantityOrderedReserved ?? 0}
                </Button>
              </ReservationModal>
            </TableCell>
            <TableCell padding={'none'} style={{ border: 'none' }}>
              <Button variant={'contained'} color={'secondary'} style={buttonStyle}>
                {quantityOrderedAvailable ?? 0}
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  },
);

StoreValuesContent.displayName = 'StoreValuesContent';

const StoreValues = ({ product, renderFetch = false }) => {
  const [currentProduct, setCurrentProduct] = useState(product);

  const fetch = () => {
    ProductModule.api.get({ id: product.id }).then(result => {
      setCurrentProduct(result);
    });
  };

  useEffect(() => {
    if (renderFetch) {
      fetch();
    }

    const interval = setInterval(fetch, 60000);
    return () => clearInterval(interval);
  }, []);

  if (!currentProduct.hasOwnProperty('quantityStock')) {
    return <>Ładowanie...</>;
  }

  return (
    <StoreValuesContent
      id={currentProduct?.id}
      quantityOrderedAvailable={currentProduct?.quantityOrderedAvailable}
      quantityStock={currentProduct?.quantityStock}
      quantityOrdered={currentProduct?.quantityOrdered}
      quantityAvailable={currentProduct?.quantityAvailable}
      quantityReserved={currentProduct?.quantityReserved}
      quantityOrderedReserved={currentProduct?.quantityOrderedReserved}
    />
  );
};

export default StoreValues;
