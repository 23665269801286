import BaseScalarInput, { BaseScalarInputType } from 'components/CustomFields/FieldTypes/BaseScalarInput';
import Editor from 'components/Form/Editor';

const TextareaField: BaseScalarInputType = props => {
  const { field, value, onChange, type, inputProps = {}, readonly, errorKey, customProps = {} } = props;

  const isReadonly = readonly || field.config.readonly;

  const onEditorChange = value => {
    onChange(value, field.id);
  };

  const overriddenType = customProps?.overriddenEditor ?? field.config.editor;

  switch (overriddenType) {
    case 'CKEDITOR':
      return (
        <div className={'cf-text-editor'}>
          <Editor
            label={field.name || 'Nazwa pola'}
            value={value}
            name={field.id}
            type={type}
            onChange={onEditorChange}
            inputProps={{ ...inputProps, tabIndex: field._tabindex }}
            disabled={isReadonly}
            labelBadge={field.position}
            errorKey={errorKey}
            disableGroupMargin={true}
            fullWeight={true}
            fieldId={field.id}
            labelerId={field.labelerId}
            {...customProps}
          />
        </div>
      );
    default:
      return BaseScalarInput({
        ...props,
        readonly: isReadonly,
        type: 'textarea',
        inputProps: { style: { height: '100%' } },
      });
  }
};

export default TextareaField;
