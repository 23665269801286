import Contractor from '../Contractor/Contractor';
import Employee from '../Employee/Employee';
import Errand from '../Manufacture/Errand';
import Technology from '../Manufacture/Technology';
import AmountColumn from './Components/List/AmountColumn';
import ListPrepend from './Components/List/ListPrepend';
import SelectionMergeTasks from './Components/List/SelectionMergeTasks';
import StatusPicker from './Components/List/StatusPicker';
import UserPicker from './Components/List/UserPicker';
import { StatusValues } from './Types/Statuses';
import { Task } from './Types/Task';
import CTMModule, { CTMApiMixed, CTMListColumn } from 'Modules/Core/Types/CTMModule';
import { SelectColumnFilter, SelectModuleRecordColumnFilter } from 'components/DataGrid/Filter';
import { post } from 'helpers/Axios';
import { openTask } from 'store/TaskManagement/Actions';

const columns: CTMListColumn<Task>[] = [
  {
    id: 'key',
    Header: 'Klucz',
    accessor: 'key',
    filterable: true,
    sortable: true,
  },
  {
    id: 'errand.id',
    Header: 'Zlecenie',
    accessor: 'errand.number',
    Filter: SelectModuleRecordColumnFilter,
    filterModule: Errand,
    filterable: true,
    sortable: false,
  },
  {
    id: 'errand.contractor.id',
    Header: 'Kontrahent',
    accessor: 'errand.contractor.name',
    Filter: SelectModuleRecordColumnFilter,
    filterModule: Contractor,
    filterable: true,
    sortable: false,
  },
  {
    id: 'name',
    Header: 'Nazwa',
    accessor: 'name',
    filterable: true,
    sortable: true,
  },
  {
    id: 'product.technology.id',
    Header: 'Technologia',
    accessor: 'product.technology.name',
    Filter: SelectModuleRecordColumnFilter,
    filterModule: Technology,
    filterable: true,
    sortable: false,
  },
  {
    id: 'amountToMake',
    Header: 'Do zrobienia',
    accessor: row => <AmountColumn key={row.id} task={row} />,
    filterable: false,
    sortable: false,
  },
  {
    id: 'status',
    Header: 'Status',
    accessor: ({ id, status }) => <StatusPicker key={id} id={id} value={status} />,
    Filter: SelectColumnFilter,
    filterOptions: [
      { value: null, label: 'Wszystkie' },
      { value: '' + StatusValues.canceled, label: 'Anulowane' },
      { value: '' + StatusValues.created, label: 'Utworzone' },
      { value: '' + StatusValues.waiting, label: 'Oczekujące' },
      { value: '' + StatusValues.readyForWork, label: 'Gotowe do pracy' },
      { value: '' + StatusValues.inProgress, label: 'W toku' },
      { value: '' + StatusValues.done, label: 'Gotowe' },
    ],
    filterable: true,
    sortable: true,
  },
  {
    id: 'assignedEmployee.id',
    Header: 'Pracownik',
    accessor: row => <UserPicker key={row.id} task={row} />,
    Filter: SelectModuleRecordColumnFilter,
    filterModule: Employee,
    filterable: true,
    sortable: false,
  },
];

interface ChangeAmount {
  comment?: null | string;
  task: string;
  amount: number;
  unit?: null | string;
}

type CustomOperations = {
  addComment: (taskIRI: string, comment: string) => Promise<any>;
  changeAmount: (ChangeAmount) => Promise<any>;
};

const module: CTMModule<Task, CTMApiMixed, CTMApiMixed, CustomOperations> = {
  id: 'TaskModule',
  dataClass: 'CTM\\TaskManagement\\Entity\\AbstractTask',
  urlPrefix: 'task-management-abstract-task',
  name: 'Zadania',
  role: 'TASK_MANAGEMENT_TASK',
  api: {
    item: {
      get: ({ id }) => `/task-management/tasks/${id}`,
      put: ({ id }) => `/task-management/tasks/${id}`,
      delete: ({ id }) => `/task-management/tasks/${id}`,
    },
    collection: {
      get: `/task-management/tasks`,
      post: `/task-management/tasks`,
    },
    custom: {
      addComment: (taskIRI, comment) => post('/task-management/task-comments', { task: taskIRI, comment: comment }),
      changeAmount: changeAmount => post('task-management/task-amount-changes', changeAmount),
    },
  },
  recordLabel: ({ name }) => name,
  list: {
    prependComponent: ListPrepend,
    columns: columns,
    defaultFilters: [],
    defaultOrderBy: [{ id: 'createdAt', desc: true }],
    onRowClick: (row, { dispatch }, cell) => {
      if (!['status', 'assignedEmployee.id', 'selection'].includes(cell.column.id)) {
        dispatch(openTask(row.original['@id']));
      }
    },
    resolveQueryKey: rootStore => rootStore.TaskManagement.queryKey,
    selectionActions: SelectionMergeTasks,
    storeFilters: true,
  },
};

export default module;
