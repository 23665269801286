import { Button } from '@mui/material';
import { Status, usePropertyValues } from 'Modules/Core/Hooks/usePropertyValues';
import ListPicker from 'components/Form/ListPicker';
import Loader from 'components/Theme/Common/Loader';
import { FC } from 'react';

interface StatusPickerProps {
  field: any;
  value: null | string;
  onChange: any;
  readonly: boolean;
  errorKey: null | string;
  moduleName: string;
  propertyName: string;
}

const StatusPicker: FC<StatusPickerProps> = ({ field, value, onChange, readonly, errorKey, moduleName, propertyName }) => {
  const { data: statuses, isLoading } = usePropertyValues(moduleName, propertyName);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <ListPicker<Status>
      name={field.id}
      label={field.name}
      tabIndex={field._tabindex}
      value={(value !== null ? value : field.defaultValue) ?? 500}
      options={statuses ?? []}
      optionLabel={item => (
        <Button
          variant={'contained'}
          style={{
            width: '100%',
            padding: '3px',
            color: '#fff',
            margin: '0 2px',
            backgroundColor: item?.color,
          }}
        >
          {item?.label}
        </Button>
      )}
      optionTrackBy="value"
      disabled={readonly}
      errorKey={errorKey}
      onChange={value => onChange(value, field.id)}
    />
  );
};

export default StatusPicker;
