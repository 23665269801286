import { CustomFieldComponentType } from '@Components/CustomFields/CustomField';
import { Button } from '@mui/material';
import { usePropertyValues } from 'Modules/Core/Hooks/usePropertyValues';
import ListPicker from 'components/Form/ListPicker';
import Loader from 'components/Theme/Common/Loader';

const StatusPicker: CustomFieldComponentType = ({ field, value, onChange, readonly, errorKey }) => {
  const { data: statuses, isLoading } = usePropertyValues('contractor-lead', 'status');

  if (isLoading || !field) {
    return <Loader />;
  }

  return (
    <ListPicker
      name={field.id}
      label={field.name}
      tabIndex={field._tabindex}
      value={(value || field.defaultValue) ?? 500}
      options={statuses ?? []}
      optionLabel={item => (
        <Button
          variant={'contained'}
          style={{
            width: '100%',
            padding: '3px',
            color: '#fff',
            margin: '0 2px',
            backgroundColor: item?.color,
          }}
        >
          {item?.label}
        </Button>
      )}
      optionTrackBy="value"
      disabled={readonly}
      errorKey={errorKey}
      onChange={val => onChange(val, field.id)}
    />
  );
};

export default StatusPicker;
