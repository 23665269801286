import { Add, HighlightOff } from '@mui/icons-material';
import DateTimePickerInput from 'components/Form/MUI/DateTimePickerInput';
import TextInput from 'components/Form/MUI/TextInput';
import Loader from 'components/Theme/Common/Loader';
import { Col, Container } from 'components/Theme/Grid';
import { post } from 'helpers/Axios';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalBody } from 'reactstrap';
import { addSingleToast } from 'store/Toast/actions';

interface AddEventModalProps {
  open: boolean;
  onClose: () => void;
}

interface Form {
  date: string;
  title: string;
}

const AddEventModal: FC<AddEventModalProps> = ({ open, onClose }) => {
  const [form, setForm] = useState<Form>({ date: '', title: '' });
  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useDispatch<any>();

  const updateFormValue = (value: string | null | undefined, field: string | undefined): void => {
    if (typeof field !== 'string') {
      return;
    }

    setForm({ ...form, [field]: value });
  };

  const save = (): void => {
    setLoading(true);
    post('calendar/events', form)
      .then(() => {
        onClose();
        dispatch(addSingleToast({ title: `Dodano wydarzenie.`, config: { appearance: 'success' } }));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Modal
      style={{ maxWidth: '400px' }}
      isOpen={open}
      centered={true}
      size={'sm'}
      backdrop={true}
      toggle={() => onClose()}
      backdropClassName={'asd'}
    >
      <ModalBody className="py-3 px-5">
        <HighlightOff style={{ position: 'absolute', top: 10, right: 10 }} onClick={() => onClose()} />
        <h3>Nowe wydarzenie</h3>
        <Container spacing={2}>
          <Col xs={12}>
            <DateTimePickerInput label={'Data'} value={form.date} name="date" onChange={updateFormValue} withTime={true} />
          </Col>
          <Col xs={12}>
            <TextInput label={'Tytuł'} value={form.title} name="title" onChange={updateFormValue} />
          </Col>
          <Col xs={12}>
            <Button variant={'contained'} color={'success'} style={{ width: '100%' }} onClick={save}>
              <Add /> Dodaj
            </Button>
          </Col>
        </Container>
      </ModalBody>
    </Modal>
  );
};

export default AddEventModal;
