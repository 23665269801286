import { get } from '../../../../helpers/Axios';
import { Button } from '@mui/material';
import ListPicker from 'components/Form/ListPicker';
import { FC, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

interface SelectCountryFilterProps {
  column: any;
}

export const SelectCountryFilter: FC<SelectCountryFilterProps> = ({ column }) => {
  const [val, setVal] = useState(column.filterValue ?? null);
  const { data: combinations, isLoading } = useQuery(
    '/delivery/fee/combinations',
    () => get('/delivery/fee/combinations').then(res => res['hydra:member']),
    {
      cacheTime: 10 * 60 * 1000,
      staleTime: 10 * 60 * 1000,
      refetchOnWindowFocus: true,
      refetchInterval: false,
    },
  );

  useEffect(() => {
    setVal(column.filterValue ?? null);
  }, [column.filterValue]);

  if (isLoading) {
    return <></>;
  }

  const handleOnChange = value => {
    if (value !== null) {
      setVal(value);
      column.setFilter(value);
    } else {
      setVal(null);
      column.setFilter(null);
    }
  };

  return (
    <ListPicker
      className="_"
      size={{ md: 12 }}
      value={val}
      name={'search-field'}
      onChange={handleOnChange}
      options={[{ id: null, label: 'Wszystkie' }, ...combinations]}
      optionLabel={(item: any) => (
        <Button
          variant={'contained'}
          style={{
            width: '100%',
            padding: '3px',
            color: '#fff',
            margin: '0 2px',
          }}
          onClick={() => handleOnChange(item.value)}
        >
          {item.hasOwnProperty('label') && <>{item.label}</>}
          {!item.hasOwnProperty('label') && (
            <>
              <span className={'fi fi-' + item.countryFrom.toLowerCase()}></span>
              &nbsp;{item.countryFrom} → <span className={'fi fi-' + item.countryTo.toLowerCase()}></span>
              &nbsp;{item.countryTo}
            </>
          )}
        </Button>
      )}
      placeholder={`${column.Header}...`}
      optionTrackBy={'id'}
    />
  );
};
