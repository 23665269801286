import BaseInput from 'components/Form/BaseInput';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { InputGroupText } from 'reactstrap';
import { addSingleToast } from 'store/Toast/actions';
import swal from 'sweetalert';

const PinField = ({ field, value, onChange, readonly }) => {
  const [showPin, setShowPin] = useState(false);
  const dispatch = useDispatch();

  const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
  };

  const generateNewPin = () => {
    if (!value || value.length === '') {
      onChange(getRandomInt(100000, 999999), field.id);
      return;
    }
    swal({
      title: 'Czy jesteś pewien?',
      text: 'Zamierzasz wygenerować nowy PIN, po zapisaniu formularza użytkownik staci możliwość logowania za pomocą poprzedniego numeru PIN',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Anuluj',
          value: null,
          visible: true,
        },
        confirm: {
          text: 'Tak, jestem pewien',
          visible: true,
          value: true,
          closeModal: false,
        },
      },
    }).then(r => {
      if (r) {
        onChange(getRandomInt(100000, 999999), field.id);
        swal.close();
      }
    });
  };

  return (
    <>
      <BaseInput
        size={{ md: 12 }}
        label={field.name}
        labelBadge={field.position}
        value={value}
        name={field.id}
        type={showPin ? 'number' : 'password'}
        onChange={onChange}
        disabled={readonly}
        inputProps={{
          maxLength: 6,
          onKeyDown: e => {
            if (
              ((value + '').length >= 6 && e.key !== 'Backspace') ||
              !['Backspace', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(e.key)
            ) {
              e.preventDefault();
            }
          },
        }}
        afterInputContent={
          <>
            {(value + '').length > 1 && (
              <InputGroupText>
                <i
                  className={`mdi mdi-content-copy pointer`}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    dispatch(addSingleToast({ title: `Skopiowano pin do schowka`, config: { appearance: 'success' } }));
                    navigator.clipboard.writeText(value);
                  }}
                />
              </InputGroupText>
            )}
            {!readonly && (
              <InputGroupText>
                <i className={`mdi mdi-cached`} style={{ cursor: 'pointer' }} onClick={generateNewPin} />
              </InputGroupText>
            )}
            <InputGroupText>
              <i className={`mdi mdi-eye${showPin ? '-off' : ''}`} style={{ cursor: 'pointer' }} onClick={() => setShowPin(!showPin)} />
            </InputGroupText>
          </>
        }
      />
    </>
  );
};

export default PinField;
