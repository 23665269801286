import ModuleForm from 'components/Module/ModuleForm';
import SecuredView from 'components/Theme/Common/SecuredView';
import { useModuleContext } from 'context/ModulesContext';
import { useErrorHandler } from 'helpers/FormHandler';
import { FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';

const InlineForm: FC<{ readonly: boolean; forceModule?: string }> = ({ readonly, forceModule }) => {
  const { id, module } = useParams<{ id?: string; module?: string }>();
  const errorHandler = useErrorHandler();
  const history = useHistory();
  const moduleContext = useModuleContext(module ?? forceModule ?? '');
  const role = (id ? (readonly ? 'ROLE_SHOW_' : 'ROLE_EDIT_') : 'ROLE_CREATE_') + moduleContext?.configuration.role;
  return (
    <SecuredView role={role} displayError={true}>
      <ModuleForm
        moduleName={module}
        id={id}
        showContextActions={true}
        afterSave={(record, type) => {
          if (type === 'POST' && moduleContext?.showUrl) {
            history.push(moduleContext?.editUrl(record.id));
          }
        }}
        onError={err => {
          errorHandler(err);
        }}
        readonly={readonly}
      />
    </SecuredView>
  );
};

export default InlineForm;
