import { Field } from '@CustomFields/Field';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Product } from 'Modules/Manufacture/Types/Product';
import BaseInput from 'components/Form/BaseInput';
import { Col, Container } from 'components/Theme/Grid';
import { FC } from 'react';

interface ProductPricesProps {
  record: Product;
  onUpdate: any;

  setRecord: any;
  readonly: boolean;
  fields: Field[];
}

const ProductPrices: FC<ProductPricesProps> = ({ record, onUpdate }) => {
  const updateFormValue = (value, field, key) => {
    const priceQuantities = record.priceValues.priceQuantities;
    priceQuantities[key][field] = value;

    onUpdate(prevRecord => ({
      ...prevRecord,
      priceValues: { ...record.priceValues, priceQuantities: [...priceQuantities] },
    }));
  };

  return (
    <Container spacing={2}>
      <Col xs={4}>
        <Col xs={12} className={'p-3'}>
          <h4>Ceny stałe</h4>
        </Col>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nazwa</TableCell>
              <TableCell>Cena netto</TableCell>
              <TableCell>Cena brutto VAT 23%</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Cena FIFO</TableCell>
              <TableCell>
                <BaseInput
                  disableGroupMargin={true}
                  value={record?.fifoPrice?.toFixed(2) ?? 0}
                  type={'number'}
                  name={''}
                  disabled={true}
                  onChange={() => {
                    return;
                  }}
                />
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
            {record.priceValues?.priceQuantities.map(item => (
              <TableRow key={item.id}>
                <TableCell>{item.tariff.name}</TableCell>
                <TableCell>
                  <BaseInput
                    disableGroupMargin={true}
                    value={((record?.fifoPrice ?? 0) * (1 + (item?.tariff.profit ?? 0) / 100)).toFixed(2)}
                    type={'number'}
                    name={''}
                    disabled={true}
                    onChange={() => {
                      return;
                    }}
                  />
                </TableCell>
                <TableCell>
                  <BaseInput
                    disableGroupMargin={true}
                    value={((record?.fifoPrice ?? 0) * (1 + (item?.tariff.profit ?? 0) / 100) * 1.23).toFixed(2)}
                    type={'number'}
                    name={''}
                    disabled={true}
                    onChange={() => {
                      return;
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Col>
      <Col xs={8}>
        <Col xs={12} className={'p-3'}>
          <h4>Ceny ilościowe</h4>
        </Col>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nazwa</TableCell>
              <TableCell>Ilość od</TableCell>
              <TableCell>Ilość do</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell colSpan={3} style={{ height: '85px' }}></TableCell>
            </TableRow>
            {record.priceValues?.priceQuantities.map((item, key) => (
              <TableRow key={key}>
                <TableCell>{item.tariff.name}</TableCell>
                <TableCell>
                  <BaseInput
                    disableGroupMargin={true}
                    value={item.from}
                    type={'number'}
                    name={'from'}
                    onChange={(value, field) => {
                      updateFormValue(parseInt(value), field, key);
                    }}
                  />
                </TableCell>
                <TableCell>
                  <BaseInput
                    disableGroupMargin={true}
                    value={item.to}
                    type={'number'}
                    name={'to'}
                    onChange={(value, field) => {
                      updateFormValue(parseInt(value), field, key);
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Col>
    </Container>
  );
};

export default ProductPrices;
