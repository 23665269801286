import { Close } from '@mui/icons-material';
import Button from '@mui/material/Button';
import ListPicker from 'components/Form/ListPicker';
import NumberInput from 'components/Form/MUI/NumberInput';
import { Col, Container } from 'components/Theme/Grid';
import { FC, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import { addSingleToast } from 'store/Toast/actions';

interface EnterQuantityInputModalProps {
  onClose: () => void;
  productEan: string;
  productName: string;
  onSaveHandler: (quantity: number, place: any) => void;
  locations: any[];
}

const EnterQuantityInputModal: FC<EnterQuantityInputModalProps> = props => {
  const { onClose, productName, onSaveHandler, locations } = props;
  const [opened, setOpened] = useState<boolean>(true);
  const [value, setValue] = useState<number>(0);
  const [selectedPlace, setSelectedPlace] = useState<any | null>(null);
  const inputRef = useRef<HTMLInputElement>();
  const [inputKey, setInputKey] = useState<number>(0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!opened) {
      onClose();
    }
  }, [opened]);

  useEffect(() => {
    setTimeout(() => {
      if (inputRef.current) {
        // @ts-ignore
        inputRef.current.focus();
      }
    }, 100);
  }, []);
  const onSave = () => {
    onSaveHandler(value, selectedPlace?.place ?? null);
  };

  const handleNumberInputChange = (val: number | null) => {
    const stringableVal = (val ?? '') + '';

    if (stringableVal.length > 4) {
      if (stringableVal.includes(props.productEan)) {
        setValue(value + 1);
      } else {
        setInputKey(inputKey + 1);
        dispatch(
          addSingleToast({
            title: 'Błędny kod EAN.',
            config: { appearance: 'error' },
          }),
        );
        setTimeout(() => {
          if (inputRef.current) {
            // @ts-ignore
            inputRef.current.focus();
          }
        }, 50);
      }
    } else {
      setValue(val ?? value);
    }
  };

  return (
    <>
      <Modal
        isOpen={opened}
        centered={true}
        size={'xl'}
        backdrop={true}
        toggle={() => setOpened(!opened)}
        style={{ maxWidth: 520, maxHeight: '90vw', background: 'transparent' }}
        className={'task-modal'}
      >
        <ModalBody className={'amount-change-modal'}>
          <Container style={{ position: 'relative', paddingTop: 30 }} spacing={0}>
            <Button style={{ position: 'absolute', top: 10, right: 4 }} onClick={() => setOpened(false)}>
              <Close />
            </Button>
            <Col xs={12}>
              <h3 style={{ textAlign: 'center', padding: 12 }}>Wprowadź skompletowaną ilość {productName}</h3>
            </Col>
            <Col xs={12}>
              <Container style={{ padding: 28, textAlign: 'center' }}>
                <Col xs={12}>
                  <NumberInput
                    key={`number-input-${inputKey}`}
                    value={value}
                    className={'change-input'}
                    onChange={handleNumberInputChange}
                    inputProps={{ inputRef: inputRef }}
                  />
                </Col>
                <Col xs={12}>
                  <Container>
                    <Col xs={4} md={2}>
                      <Button color={'success'} variant={'contained'} onClick={() => setValue(value + 1)}>
                        +1
                      </Button>
                    </Col>
                    <Col xs={4} md={2}>
                      <Button color={'success'} variant={'contained'} onClick={() => setValue(value + 5)}>
                        +5
                      </Button>
                    </Col>
                    <Col xs={4} md={2}>
                      <Button color={'success'} variant={'contained'} onClick={() => setValue(value + 10)}>
                        +10
                      </Button>
                    </Col>
                    <Col xs={4} md={2}>
                      <Button color={'success'} variant={'contained'} onClick={() => setValue(value + 50)}>
                        +50
                      </Button>
                    </Col>
                    <Col xs={4} md={2}>
                      <Button color={'success'} variant={'contained'} onClick={() => setValue(value + 100)}>
                        +100
                      </Button>
                    </Col>
                    <Col xs={4} md={2}>
                      <Button color={'success'} variant={'contained'} onClick={() => setValue(value + 1000)}>
                        +1000
                      </Button>
                    </Col>
                  </Container>
                </Col>
                <Col xs={12}>
                  <Container>
                    <Col xs={4} md={2}>
                      <Button color={'error'} variant={'contained'} onClick={() => setValue(value - 1)}>
                        -1
                      </Button>
                    </Col>
                    <Col xs={4} md={2}>
                      <Button color={'error'} variant={'contained'} onClick={() => setValue(value - 5)}>
                        -5
                      </Button>
                    </Col>
                    <Col xs={4} md={2}>
                      <Button color={'error'} variant={'contained'} onClick={() => setValue(value - 10)}>
                        -10
                      </Button>
                    </Col>
                    <Col xs={4} md={2}>
                      <Button color={'error'} variant={'contained'} onClick={() => setValue(value - 50)}>
                        -50
                      </Button>
                    </Col>
                    <Col xs={4} md={2}>
                      <Button color={'error'} variant={'contained'} onClick={() => setValue(value - 100)}>
                        -100
                      </Button>
                    </Col>
                    <Col xs={4} md={2}>
                      <Button color={'error'} variant={'contained'} onClick={() => setValue(value - 1000)}>
                        -1000
                      </Button>
                    </Col>
                  </Container>
                </Col>
              </Container>
            </Col>
            <Col xs={12}>
              <Container style={{ padding: 28, textAlign: 'center' }}>
                <Col xs={12}>
                  <ListPicker
                    name={'locations'}
                    optionLabel={item => item.place.name}
                    options={locations.filter(item => item.place)}
                    placeholder={'Wybierz lokalizacje...'}
                    value={selectedPlace}
                    onChange={selected => setSelectedPlace(selected ?? null)}
                  />
                </Col>
              </Container>
            </Col>
            <Col xs={12} style={{ textAlign: 'center', padding: 20 }}>
              <Button color={'success'} variant={'contained'} fullWidth={true} onClick={onSave}>
                Dodaj
              </Button>
            </Col>
          </Container>
        </ModalBody>
      </Modal>
    </>
  );
};

export default EnterQuantityInputModal;
