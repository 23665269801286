import { Card } from '@mui/material';
import useModuleData from 'Modules/Core/Hooks/useModuleData';
import { confirmRemove } from 'common/sweetalerts';
import DataGrid from 'components/DataGrid';
import ModalFormWrapper from 'components/Form/ModalFormWrapper';
import ModuleForm from 'components/Module/ModuleForm';
import SecuredView from 'components/Theme/Common/SecuredView';
import { useModuleContext } from 'context/ModulesContext';
import useErrorHandler from 'helpers/FormHandler';
import { Fragment, useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup, CardBody } from 'reactstrap';
import { useAppSelector } from 'store';

const ModalActionsHeaderWithModal = ({
  module,
  afterSave,
  list,
  moduleFormComponent,
  moduleFormComponentProps,
  moduleFormWrapperProps,
  overrideFormProps,
}) => {
  const createModal = useRef();
  const errorHandler = useErrorHandler();

  if (!module) {
    return null;
  }

  if (!module.configuration.form && !moduleFormComponent) {
    return <>Akcje</>;
  }

  if (moduleFormComponent) {
    moduleFormComponentProps = { ...moduleFormComponentProps, modal: createModal };
  }

  const ModuleFormComponent = moduleFormComponent ?? ModuleForm;

  return (
    <SecuredView role={module.configuration.roleEdit ?? `ROLE_EDIT_${module.configuration.role}`} alternativeContent={'Akcje'}>
      <ModalFormWrapper
        {...moduleFormWrapperProps}
        ref={createModal}
        title={`${module.configuration.name} - Dodaj`}
        form={
          <ModuleFormComponent
            list={list}
            {...moduleFormComponentProps}
            moduleName={module.configuration.urlPrefix}
            id={null}
            showContextActions={false}
            showBackButton={false}
            afterSave={record => {
              afterSave?.(record);
              list?.current?.refresh();
              createModal?.current?.close();
            }}
            onError={errorHandler}
            readonly={false}
            overrideFormProps={overrideFormProps}
          />
        }
      >
        <Button className="btn btn-success btn-sm w-100" color="primary">
          <i className="mdi mdi-plus" />
          &nbsp;Dodaj nowy
        </Button>
      </ModalFormWrapper>
    </SecuredView>
  );
};

export const InlineActionsHeader = ({ module, override, listRef, useModal, overrideFormProps = {} }) => {
  if (!module) {
    return null;
  }

  if (module.configuration.form.disableCreateNewRecord) {
    return 'Akcje';
  }

  if (useModal) {
    return <ModalActionsHeaderWithModal module={module} override={override} overrideFormProps={overrideFormProps} list={listRef} />;
  }

  return (
    <SecuredView role={module.configuration.roleEdit ?? `ROLE_EDIT_${module.configuration.role}`} alternativeContent={'Akcje'}>
      <Link to={override.createUrl ?? module.createUrl} className="btn btn-success btn-sm w-100" color="primary">
        <i className="mdi mdi-plus" />
        &nbsp;Dodaj nowy
      </Link>
    </SecuredView>
  );
};

export const InlineListActions = (
  errorHandler,
  listRef,
  editModal,
  showModal,
  module,
  override = {},
  overrideFormProps = {},
  useModal = false,
  moduleFormWrapperProps,
) => {
  if (!module || !module.configuration.form) {
    return [];
  }
  const EditWrapper = module.configuration.editButtonWrapper ?? Fragment;

  return [
    {
      id: 'actionsStickyRight',
      Header: () => (
        <InlineActionsHeader
          module={module}
          override={override}
          useModal={useModal}
          listRef={listRef}
          overrideFormProps={overrideFormProps}
        />
      ),
      disableSortBy: true,
      disableFilters: true,
      disableExpandAction: () => true,
      accessor: row =>
        useModal ? (
          <div className={'actions'}>
            <ButtonGroup>
              {module.configuration.form && (
                <>
                  {!module.configuration.form.disableShowRecord && (
                    <ModalFormWrapper
                      {...moduleFormWrapperProps}
                      ref={showModal}
                      title={`${module.configuration.name} - ${module.configuration.recordLabel(row)} - Podgląd`}
                      form={
                        <ModuleForm
                          moduleName={module.configuration.urlPrefix}
                          id={row.id}
                          showContextActions={false}
                          showBackButton={false}
                          showConfigurationSwitcher={false}
                          afterSave={() => {
                            showModal.current.close();
                            listRef.current.refresh();
                          }}
                          onError={errorHandler}
                          readonly={true}
                          overrideFormProps={{ ...overrideFormProps, recordCallback: undefined }}
                        />
                      }
                      wrapperComponent={Button}
                      wrapperProps={{ className: 'btn btn-success btn-sm' }}
                    >
                      <i className="mdi mdi-eye" style={{ padding: '0 4px' }} />
                    </ModalFormWrapper>
                  )}
                  {!module.configuration.form.disableEditRecord && (
                    <SecuredView role={module.configuration.roleEdit ?? `ROLE_EDIT_${module.configuration.role}`}>
                      <ModalFormWrapper
                        {...moduleFormWrapperProps}
                        ref={editModal}
                        title={`${module.configuration.name} - ${module.configuration.recordLabel(row)} - Edytuj`}
                        form={
                          <ModuleForm
                            moduleName={module.configuration.urlPrefix}
                            id={row.id}
                            showContextActions={false}
                            showBackButton={false}
                            afterSave={() => {
                              editModal.current.close();
                              listRef.current.refresh();
                            }}
                            onError={errorHandler}
                            overrideFormProps={{ ...overrideFormProps, recordCallback: undefined }}
                            readonly={false}
                          />
                        }
                        wrapperComponent={Button}
                        wrapperProps={{ className: 'btn btn-primary btn-sm', color: 'primary' }}
                      >
                        <i className="mdi mdi-cogs" style={{ padding: '0 4px' }} />
                      </ModalFormWrapper>
                    </SecuredView>
                  )}
                  {!module.configuration.form.disableRemoveRecord && (
                    <SecuredView role={`ROLE_REMOVE_${module.configuration.role}`}>
                      <Button
                        onClick={() => confirmRemove(() => module.api.delete({ id: row.id }).then(() => listRef.current?.refresh()))}
                        className="btn btn-danger  btn-sm"
                      >
                        <i className="mdi mdi-delete" style={{ padding: '0 4px' }} />
                      </Button>
                    </SecuredView>
                  )}
                </>
              )}
            </ButtonGroup>
          </div>
        ) : (
          <ButtonGroup>
            {module.configuration.list.inlineComponentPrependActions &&
              module.configuration.list.inlineComponentPrependActions(row, listRef.current)}
            {!module.configuration.form.disableShowRecord && (
              <Link to={override.showUrl ? override.showUrl(row) : module.showUrl(row.id)} className="btn btn-success btn-sm">
                <i className="mdi mdi-eye" />
              </Link>
            )}
            {!module.configuration.form.disableEditRecord && (
              <EditWrapper row={row}>
                <SecuredView role={module.configuration.roleEdit ?? `ROLE_EDIT_${module.configuration.role}`}>
                  <Link to={override.editUrl ? override.editUrl(row) : module.editUrl(row.id)} className="btn btn-primary btn-sm">
                    <i className="mdi mdi-cogs" />
                  </Link>
                </SecuredView>
              </EditWrapper>
            )}
            {!module.configuration.form.disableRemoveRecord && (
              <SecuredView role={`ROLE_REMOVE_${module.configuration.role}`}>
                <Button
                  onClick={() =>
                    confirmRemove(() => module.api.delete({ id: row.id }))
                      .then(() => listRef.current?.refresh())
                      .catch(() => {})
                  }
                  className="btn btn-danger  btn-sm"
                >
                  <i className="mdi mdi-delete" />
                </Button>
              </SecuredView>
            )}
            {module.configuration.list.inlineComponentAppendActions &&
              module.configuration.list.inlineComponentAppendActions(row, listRef.current)}
          </ButtonGroup>
        ),
    },
  ];
};

const ModuleList = ({ moduleName, useModal = false, defaultFilters, overrideFormProps = {}, overrideListProps = {} }) => {
  const module = useModuleContext(moduleName);
  const list = useRef();
  const editModal = useRef();
  const showModal = useRef();
  const errorHandler = useErrorHandler();
  const { data: moduleData } = useModuleData(module.configuration.id);

  const columns = useMemo(
    () => [
      ...(typeof overrideListProps?.columns === 'function'
        ? overrideListProps?.columns(module?.configuration?.list?.columns) ?? []
        : overrideListProps?.columns ?? module?.configuration?.list?.columns ?? []),
      ...InlineListActions(errorHandler, list, editModal, showModal, module, undefined, overrideFormProps, useModal),
    ],
    [module],
  );
  const queryKey = useAppSelector(module.configuration.list?.resolveQueryKey ? module.configuration.list?.resolveQueryKey : () => false);

  if (!module) {
    return <>Unknown module {moduleName}</>;
  }

  const ListPrepend = module.configuration.list.prependComponent;
  const exports = [...(moduleData?.exports ?? []), ...(module.configuration?.list?.customExports ?? [])];
  return (
    <Card>
      <CardBody style={{ padding: 0 }}>
        {ListPrepend && <ListPrepend listRef={list} />}
        <DataGrid
          ref={list}
          url={overrideListProps.url ?? module.api.getAllUrl}
          fullWidth={overrideListProps.fullWidth ?? false}
          responsive={overrideListProps.responsive ?? true}
          className={overrideListProps.className ?? 'table-clickable'}
          columns={columns}
          exports={exports}
          defaultPerPage={overrideListProps.defaultPerPage ?? 20}
          perPageOptions={overrideListProps.perPageOptions ?? [10, 20, 30, 40, 50]}
          defaultOrderBy={overrideListProps.defaultOrderBy ?? module.configuration.list.defaultOrderBy}
          renderRowSubComponent={overrideListProps.renderRowSubComponent ?? module.configuration.list.renderRowSubComponent}
          defaultFilters={defaultFilters ?? module.configuration.list.defaultFilters}
          onRowClick={overrideListProps.onRowClick ?? module.configuration.list.onRowClick}
          rowStylesCallback={overrideListProps.onRowClick ?? module.configuration.list.rowStylesCallback}
          canRenderSubRow={overrideListProps.canRenderSubRow ?? module.configuration.list.canRenderSubRow}
          selectionActions={overrideListProps.selectionActions ?? module.configuration.list.selectionActions}
          queryKey={overrideListProps.queryKey ?? queryKey}
          storeFilters={overrideListProps.storeFilters ?? module.configuration.list.storeFilters}
        />
      </CardBody>
    </Card>
  );
};

export default ModuleList;
