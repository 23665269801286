import HistoryModuleButton from '../History/HistoryModuleButton';
import useCurrentUser from 'Modules/Employee/Hooks/UseCurrentUser';
import ApiForm from 'components/Theme/Common/ApiForm';
import SecuredView from 'components/Theme/Common/SecuredView';
import { useModuleContext } from 'context/ModulesContext';
import { Fragment, memo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { addSingleToast } from 'store/Toast/actions';

const ContextActionsComponent = ({ readonly, row, module }) => {
  const { id } = row;
  const EditWrapper = module.configuration.editButtonWrapper ?? Fragment;
  const location = useLocation();

  return (
    <>
      {id && <HistoryModuleButton id={id} module={module} />}
      {!module.configuration.form.disableEditRecord && readonly && id && (
        <EditWrapper row={row}>
          <SecuredView role={module.configuration.roleEdit ?? `ROLE_EDIT_${module.configuration.role}`}>
            <Link to={module.editUrl(id) + location.search} className="btn btn-primary ml-1">
              <i className="mdi mdi-pencil" /> Edycja
            </Link>
          </SecuredView>
        </EditWrapper>
      )}
      {!readonly && id && (
        <SecuredView role={`ROLE_SHOW_${module.configuration.role}`}>
          <Link to={module.showUrl(id) + location.search} className="btn btn-primary ml-1">
            <i className="mdi mdi-eye" /> Podgląd
          </Link>
        </SecuredView>
      )}
    </>
  );
};

const CustomContextActionsComponent = ({ readonly, id, record, module, ApiFormComponent }) => {
  return (
    <>
      {module.configuration.form.contextActions &&
        module.configuration.form.contextActions({
          readonly,
          record,
          id,
          module,
          ApiFormComponent,
        })}
    </>
  );
};

const CustomSubmitActionsComponent = ({ readonly, id, record, module, ApiFormComponent }) => {
  return (
    <>
      {module.configuration.form.submitActions &&
        module.configuration.form.submitActions({
          readonly,
          record,
          id,
          module,
          ApiFormComponent,
        })}
    </>
  );
};

export const ContextActions = memo(ContextActionsComponent);
export const CustomContextActions = memo(CustomContextActionsComponent);
export const CustomSubmitActions = memo(CustomSubmitActionsComponent);

const ModuleForm = ({
  module,
  id,
  afterSave,
  onError,
  showContextActions = true,
  showBackButton = true,
  readonly = true,
  showConfigurationSwitcher = true,
  overrideFormProps = {},
}) => {
  const dispatch = useDispatch();
  const ApiFormComponent = useRef();
  const currentUser = useCurrentUser();

  const onSubmit = (record, allFields) => {
    const data = module.configuration.form?.prepareRecordToSave ? module.configuration.form.prepareRecordToSave(record, allFields) : record;

    return new Promise(res => {
      if (id) {
        module.api
          .put(data, { id })
          .then(res => {
            dispatch(addSingleToast({ title: `Zapisano zmiany`, config: { appearance: 'success' } }));
            if (typeof afterSave === 'function') {
              afterSave(res, 'PUT', data);
            }
          })
          .catch(err => {
            if (typeof onError === 'function') {
              onError?.(err);
            } else {
              throw err;
            }
            res(false);
          })
          .finally(() => res(true));
      } else {
        module.api
          .post(data)
          .then(res => {
            dispatch(
              addSingleToast({
                title: `Dodano ${module.configuration.name}`,
                config: { appearance: 'success' },
              }),
            );
            if (typeof afterSave === 'function') {
              afterSave?.(res, 'POST', data);
            }
          })
          .catch(err => {
            if (typeof onError === 'function') {
              onError?.(err);
            } else {
              throw err;
            }
            res(false);
          })
          .finally(() => res(true));
      }
    });
  };

  const fetch = async allFields => {
    if (!id) {
      const defaultRecord = module.configuration.form?.defaultRecord?.(allFields, currentUser) ?? {};
      const allFieldsRaw = allFields
        .filter(el => el.system)
        .reduce((acc, field) => {
          acc[field.propertyPath] = field.id;
          return acc;
        }, {});

      return {
        ...defaultRecord,
        '@formValues': {
          ...Object.keys(allFieldsRaw).reduce((acc, key) => {
            if (defaultRecord[key] !== undefined) {
              acc[allFieldsRaw[key]] = defaultRecord[key];
            }
            return acc;
          }, {}),
          ...defaultRecord['@formValues'],
        },
      };
    }

    return new Promise((res, rej) => {
      module.api
        .get({ id })
        .then(record => {
          if (module.configuration.form.prepareFetchedRecord) {
            res(module.configuration.form.prepareFetchedRecord(record, allFields));
          } else {
            res(record);
          }
        })
        .catch(rej);
    });
  };

  if (!module) {
    return <>Unknown module {moduleName}</>;
  }

  if (!module.configuration.form) {
    return null;
  }

  return (
    <ApiForm
      key={`api-form-${module.configuration.dataClass}-${id}-${readonly ? 1 : 0}-${showBackButton ? 1 : 0}-${
        showConfigurationSwitcher ? 1 : 0
      }`}
      ref={ApiFormComponent}
      autoSave={overrideFormProps.autoSave ?? module.configuration.form.autoSave}
      dataClass={encodeURIComponent(module.configuration.dataClass)}
      recordCallback={overrideFormProps.recordCallback ? overrideFormProps.recordCallback : async allFields => fetch(allFields)}
      onSubmit={overrideFormProps.onSubmit ?? onSubmit}
      moduleId={overrideFormProps.moduleId ?? module.configuration.id}
      readonly={overrideFormProps.readonly ?? readonly}
      showBackButton={overrideFormProps.showBackButton ?? showBackButton}
      backUrl={overrideFormProps.backUrl ?? module.listUrl}
      showConfigurationSwitcher={overrideFormProps.showConfigurationSwitcher ?? showConfigurationSwitcher}
      recordLabel={overrideFormProps.recordLabel ?? module.configuration.recordLabel}
      customTabs={overrideFormProps.customTabs ?? module.configuration.form.customTabs}
      components={overrideFormProps.components ?? module.configuration.form.fieldComponents ?? {}}
      forceReadonlyField={overrideFormProps.forceReadonlyField ?? module.configuration.form.forceReadonlyField}
      onRecordChange={overrideFormProps.onRecordChange ?? module.configuration.form.onRecordChange}
      inlineComponentAppendHeader={overrideFormProps.inlineComponentAppendHeader ?? module.configuration.form.inlineComponentAppendHeader}
      resetTab={overrideFormProps.resetTab ?? false}
      trackTabInUrl={overrideFormProps.trackTabInUrl ?? true}
      contextActions={record => (
        <>
          {<CustomContextActions readonly={readonly} id={record.id} record={record} module={module} ApiFormComponent={ApiFormComponent} />}
          {showContextActions && <ContextActions readonly={readonly} row={record} module={module} />}
        </>
      )}
      submitActions={record => (
        <CustomSubmitActions readonly={readonly} id={record.id} record={record} module={module} ApiFormComponent={ApiFormComponent} />
      )}
    />
  );
};

const SecuredModuleView = props => {
  const module = useModuleContext(props.moduleName);

  return (
    <SecuredView role={`ROLE_EDIT_${module?.configuration.role}|ROLE_SHOW_${module?.configuration.role}`} displayError={true}>
      <ModuleForm {...props} module={module} />
    </SecuredView>
  );
};

export default SecuredModuleView;
